import React from 'react';
import { Box } from '@mui/material';
import Header from './Header';
import Banner from './Banner';
import Services from './Services';
import Features from './Features';
import Devices from './Devices';
import Footer from './Footer';
import Timeline from './Timeline';

const Appioayudo: React.FC = () => {
  return (
    <>
      <Header />
      <Box
        sx={{
          maxWidth: '1400px',
          margin: '0 auto',
          padding: '0 20px',
        }}
      >
        <Banner />
        <Services />
        <Devices />
        <Banner imageUrls={['/images/back3.png', '/images/back4.png', '/images/back5.png']} />
        <Features />
        <Timeline />

        
      </Box>
      
      <Footer />

      {/* Botón flotante de WhatsApp */}
      <Box
        sx={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 1000, // Asegura que esté encima de otros elementos
        }}
      >
        <a
          href="https://wa.me/4431211679?text=Hola%20me%20gustaría%20más%20información%20sobre%20sus%20servicios" // Cambia el número de teléfono
          target="_blank"
          rel="noopener noreferrer"
        >
          <Box
            component="img"
            src="/images/what.png" // Ruta de la imagen en la carpeta pública
            alt="WhatsApp"
            sx={{
              width: '140px',
              height: '140px',
              borderRadius: '50%',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)', // Sombra para destacar el botón
              transition: 'transform 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.1)', // Efecto de agrandamiento en hover
              },
            }}
          />
        </a>
      </Box>
    </>
  );
};

export default Appioayudo;
