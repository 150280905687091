import React, { FC, useState } from 'react';
import './ToggleSwitch.css'; // Asegúrate de importar tu archivo de estilos

interface ToggleSwitchProps {
  id: string;
  disabled?: boolean;
  initialChecked?: boolean;
  onLabel?: string; // Texto para el estado "on"
  offLabel?: string; // Texto para el estado "off"
}

const ToggleSwitch: FC<ToggleSwitchProps> = ({ id, disabled = false, initialChecked = false, onLabel = 'On', offLabel = 'Off' }) => {
  const [checked, setChecked] = useState(initialChecked);

  const handleToggle = () => {
    if (!disabled) {
      setChecked(!checked);
    }
  };

  return (
    <>
      <input
        className="tgl tgl-skewed"
        id={`etiket_${id}`}
        type="checkbox"
        disabled={disabled}
        checked={checked}
        onChange={handleToggle}
      />
      <label className="tgl-btn" data-tg-off={offLabel} data-tg-on={onLabel} htmlFor={`etiket_${id}`}></label>
    </>
  );
};

export default ToggleSwitch;
