import { ReactNode } from 'react';
import { CssBaseline } from '@mui/material';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import { indigo, blueGrey, orange, red, green } from '@mui/material/colors';

const theme = responsiveFontSizes(
   createTheme({
    palette: {
      mode: 'dark',  // Esto habilita el modo oscuro
      background: {
        default: "#121212",  // Color de fondo oscuro por defecto
        paper: "#1d1d1d"    // Color de fondo para los componentes que usan 'paper'
      },
      primary: indigo,      // Mantiene tus colores personalizados
      secondary: blueGrey,
      info: {
        main: indigo[500]
      },
      warning: {
        main: orange[500]
      },
      error: {
        main: red[500]
      },
      success: {
        main: green[500]
      },
      text: {
        primary: "#ffffff", // Aseguramos que el texto principal sea blanco
        secondary: "#b0bec5" // Color de texto secundario más claro
      }
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: '#333333',  // Color para el AppBar (barra superior)
          }
        }
      }
    }
  })
);

// Definir el tipo para las props que incluyen children
interface CustomThemeProps {
  children: ReactNode;
}

const CustomTheme = ({ children }: CustomThemeProps) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
);

export default CustomTheme;
