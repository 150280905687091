import React, { useLayoutEffect, useRef, useState } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import {
  ForceDirectedTree,
  ForceDirectedSeries
} from '@amcharts/amcharts4/plugins/forceDirected';

const ForceDirectedTreeComponent = ({ HDM }) => {
  const [peripheralStatus, setPeripheralStatus] = useState({});
  const chartRef = useRef(null);
  const focusedNodeRef = useRef(null);
  const treeStateRef = useRef(null); // To store the state of the tree

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const levelStyles = {
    0: { fontSize: 25, radius: 35, fontColor: '#EDE228', nodeColor: ['#660A10', '#FF4500'] },
    1: { fontSize: 15, radius: 30, fontColor: '#EDE228', nodeColor: ['#0E667D', '#1E90FF'] },
    2: { fontSize: 12, radius: 25, fontColor: '#EDE228', nodeColor: ['#6E9D9A', '#20B2AA'] },
    3: { fontSize: 10, radius: 15, fontColor: '#EDE228', nodeColor: ['#403829', '#8B4513'] },
  };

  const generateChartData = (HDM) => {
    if (Array.isArray(HDM)) {
      // Caso: HDM es una lista de dispositivos básicos
      return {
        name: 'DISPOSITIVOS',
        children: HDM.map(device => ({
          name: `${device.name}`,
          children: [
            { name: `ctd_perif: ${device.ctd_perif}`, value: 1 },
            { name: `Model: ${device.model}`, value: 1 },
            { name: `QR: ${device.qr_code}`, value: 1 },
          ]
        }))
      };
    } else if (HDM.Perifs) {
      // Caso: HDM contiene un dispositivo con periféricos detallados
      return {
        name: 'PERIFERICOS',
        children: HDM.Perifs.map(periferico => {
          const status = peripheralStatus[periferico.IDper] !== undefined
            ? peripheralStatus[periferico.IDper]
            : (periferico.state === 1);
          return {
            name: `Periferico<br>${periferico.IDName}<br>Pin: ${periferico.PinOut}`,
            children: [
              { name: `ID: ${periferico.IDper}`, value: 1 },
              { name: `Enable: ${periferico.EnGpio}`, value: 1 },
              { name: `IO: ${periferico.io}`, value: 1 },
              { name: `Type: ${periferico.GpIOTyp}`, value: 1 },
              {
                name: `Status`,
                value: 1,
                status: status,
                peripheralId: periferico.IDper,
                button: `<button id="btn-${periferico.IDper}">Show/Hide</button>`
              }
            ]
          };
        })
      };
    } else {
      // Manejo de un caso inesperado
      return { name: 'No Data' };
    }
  };
  

  const saveTreeState = (node) => {
    const state = {};
    const traverse = (node, state) => {
      if (node.children && node.children.values) {
        state[node.dataItem.id] = node.children.values.map(child => ({
          id: child.dataItem.id,
          visible: child.visible,
        }));
        console.log('Saving state for node:', node.dataItem.id, state[node.dataItem.id]);
        node.children.values.forEach(child => traverse(child, state));
      }
    };
    traverse(node, state);
    console.log('Complete saved state:', state);
    return state;
  };

  const restoreTreeState = (node, state) => {
    const traverse = (node, state) => {
      if (node.children && node.children.values) {
        const childrenState = state[node.dataItem.id];
        if (childrenState) {
          console.log('Restoring state for node:', node.dataItem.id, childrenState);
          node.children.values.forEach(child => {
            const childState = childrenState.find(c => c.id === child.dataItem.id);
            if (childState) {
              child.visible = childState.visible;
              traverse(child, state);
            }
          });
        }
      }
    };
    traverse(node, state);
    console.log('Tree state after restoration:', state);
  };

  useLayoutEffect(() => {
    // Apply theme
    am4core.useTheme(am4themes_animated);

    // Create chart
    let chart = am4core.create('chartdiv', ForceDirectedTree);
    chartRef.current = chart;
    // chart.legend = new am4charts.Legend();

    let networkSeries = chart.series.push(new ForceDirectedSeries());

    console.log(HDM)
    const data = generateChartData(HDM);

    networkSeries.data = [data];

    networkSeries.dataFields.linkWith = 'linkWith';
    networkSeries.dataFields.name = 'name';
    networkSeries.dataFields.id = 'name';
    networkSeries.dataFields.value = 'value';
    networkSeries.dataFields.children = 'children';

    networkSeries.nodes.template.tooltipText = '{name}';
    networkSeries.nodes.template.fillOpacity = 1;

  // Use html property instead of text for labels
//   networkSeries.nodes.template.label.html = true;
  networkSeries.nodes.template.label.text = '{name}';
  networkSeries.nodes.template.label.wrap = true;
  networkSeries.nodes.template.label.maxWidth = 200; // Set the max width of the label

  
    networkSeries.fontSize = 48;
    networkSeries.maxLevels = 4;
    networkSeries.maxRadius = am4core.percent(16);
    networkSeries.manyBodyStrength = -16;
    networkSeries.nodes.template.label.hideOversized = true;
    networkSeries.nodes.template.label.truncate = true;

    networkSeries.nodes.template.togglable = false;
    networkSeries.links.template.distance = 2;
    networkSeries.links.template.strokeWidth = 2;

    networkSeries.nodes.template.events.on("validated", function(event) {
      const node = event.target;
      const inputHtml = node.dataItem.dataContext.input;

      if (inputHtml) {
        let label = node.createChild(am4core.Label);
        label.html = inputHtml;
        label.fontSize = 10;
        label.isMeasured = false;
        label.x = am4core.percent(50);
        label.y = am4core.percent(50);
        label.horizontalCenter = "middle";
        label.verticalCenter = "middle";
      }
    });

    networkSeries.nodes.template.events.on('hit', function (event) {
      const node = event.target;
      const peripheralId = node.dataItem.dataContext.peripheralId;

      if (peripheralId) {
        const currentStatus = node.dataItem.dataContext.status;
        node.dataItem.dataContext.status = !currentStatus;
        node.label.text = `Status: ${!currentStatus ? 'ON' : 'OFF'}`;
      }

      const toggleChildNodes = (parentNode, visible) => {
        if (parentNode.children && parentNode.children.values) {
          parentNode.children.values.forEach(child => {
            child.visible = visible;
            toggleChildNodes(child, visible);
          });
        }
      };

      if (node === focusedNodeRef.current) {
        focusedNodeRef.current = null;
        if (node.isActive) {
          node.isActive = false;
          if (node.dataItem.dataContext.name === HDM.NAMEDEVICE) {
            console.log('Saving tree state');
            treeStateRef.current = saveTreeState(node); // Save the tree state
          }
        } else {
          node.isActive = true;
          if (node.dataItem.dataContext.name === HDM.NAMEDEVICE && treeStateRef.current) {
            console.log('Restoring tree state');
            restoreTreeState(node, treeStateRef.current); // Restore the tree state
          }
        }
      } else {
        if (!node.isActive) {
          node.isActive = true;
          if (node.dataItem.dataContext.name === HDM.NAMEDEVICE && treeStateRef.current) {
            console.log('Restoring tree state');
            restoreTreeState(node, treeStateRef.current); // Restore the tree state
          }
        }
        focusedNodeRef.current = node;
      }

      if (node.dataItem.dataContext.name === HDM.NAMEDEVICE) {
        chart.zoomOut();
      } else {
        chart.zoomToDataItem(node.dataItem, 2, true);
      }
    });

    networkSeries.nodes.template.adapter.add('tooltipText', function(text, target) {
      if (target.dataItem && target.dataItem.dataContext.status !== undefined) {
        const status = peripheralStatus[target.dataItem.dataContext.peripheralId] 
          ? 'ON' 
          : 'OFF';
        return `Status: ${status}`;
      }
      return text;
    });

    // Apply different styles to nodes based on their level
    networkSeries.nodes.template.adapter.add('radius', function(radius, target) {
      const level = target.dataItem.level;
      return levelStyles[level] ? levelStyles[level].radius : radius;
    });

    networkSeries.nodes.template.label.adapter.add('fontSize', function(fontSize, target) {
      const level = target.dataItem.level;
      return levelStyles[level] ? levelStyles[level].fontSize : fontSize;
    });

    networkSeries.nodes.template.label.adapter.add('fill', function(fill, target) {
      const level = target.dataItem.level;
      return levelStyles[level] ? am4core.color(levelStyles[level].fontColor) : fill;
    });

    networkSeries.nodes.template.adapter.add('fill', function(fill, target) {
      const level = target.dataItem.level;
      if (levelStyles[level] && levelStyles[level].nodeColor) {
        let gradient = new am4core.LinearGradient();
        gradient.addColor(am4core.color(levelStyles[level].nodeColor[0]));
        gradient.addColor(am4core.color(levelStyles[level].nodeColor[1]));
        return gradient;
      }
      return fill;
    });

    return () => {
      chart.dispose();
    };
  }, [peripheralStatus, HDM]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {/* <h3>Generated Color Palette</h3>
      {Object.keys(levelStyles).map(level => (
        <div key={level}>
          <p>Level {level} - Font Color: {levelStyles[level].fontColor}, Node Color: {levelStyles[level].nodeColor.join(', ')}</p>
        </div>
      ))} */}
      <div id="chartdiv" style={{ width: '100%', height: '100%' }}></div>
    </div>
  );
};

export default ForceDirectedTreeComponent;
