import React from 'react';
import { Container, Box } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

const Banner: React.FC = () => {
  return (
    <Container 
      sx={{
        marginBottom: 0,
        marginTop: 5,
        maxWidth: {
          xs: '100%',   // en pantallas extra pequeñas
          sm: '100%',   // en pantallas pequeñas
          md: '100%',   // en pantallas medianas
          lg: '100%',   // en pantallas grandes
          xl: '2000px', // en pantallas extra grandes
        },
      }}
    >
      <Swiper
        modules={[Autoplay, Pagination]}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        pagination={{ clickable: true }}
        loop
        style={{ width: '100%', height: 'auto', borderRadius: '8px', overflow: 'hidden' }}
      >
        <SwiperSlide>
          <Box
            component="img"
            src="/images/baner1.jpg"
            alt="Banner 1"
            sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Box
            component="img"
            src="/images/baner2.jpg"
            alt="Banner 2"
            sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Box
            component="img"
            src="/images/baner3.jpg"
            alt="Banner 3"
            sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </SwiperSlide>
      </Swiper>
    </Container>
  );
};

export default Banner;
