import React from 'react';
import { Grid, Card, CardContent, Box, Typography, ListItemIcon, ListItemText, Fade } from '@mui/material';
import { useInView } from 'react-intersection-observer';

interface Application {
    icon: React.ReactNode;
    title: string;
    description: string;
    imageUrl: string;
}

// Componente ApplicationCard con la interfaz aplicada a 'application'
const ApplicationCard: React.FC<{ application: Application }> = ({ application }) => {
    const { ref, inView } = useInView({
        triggerOnce: false, // Solo activa la animación una vez
        threshold: 0.2, // 20% del elemento visible para activar
    });

    return (
        <Grid item xs={12} md={6} ref={ref}>
            <Fade in={inView} timeout={1000}>
                <Card sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
                    <CardContent sx={{ display: 'flex', alignItems: 'center',flexDirection: { xs: 'column', sm: 'row' }}}>
                        
                        {/* Sección de texto y descripción */}
                        <Box  sx={{ width: '80%' }}>
                            <Box display="flex" alignItems="center">
                                <ListItemIcon>
                                    {application.icon}
                                </ListItemIcon>
                                <ListItemText primary={<Typography variant="h6" sx={{ fontWeight: 'bold' }}>{application.title}</Typography>} />
                            </Box>
                            <Typography variant="body2" sx={{ marginTop: 1, color: 'rgba(255, 255, 255, 0.7)',fontSize: {
                                    xs: '1rem',  // Extra pequeñas
                                    sm: '1rem',  // Pequeñas
                                    md: '1rem',  // Medianas
                                    lg: '1.5rem',  // Grandes
                                    xl: '1.5rem',  // Extra grandes
                                }, }}>
                                {application.description}
                            </Typography>
                            {/* Imagen de la aplicación */}
                        
                        </Box>

                        <Box display="flex" alignItems="end">

                            <Box
                                component="img"
                                src={application.imageUrl}
                                alt={application.title}
                                sx={{
                                    width: 100,
                                    height: 100,
                                    borderRadius: 2,
                                    objectFit: 'cover',
                                    marginRight: 2,
                                }}
                            />
                        </Box>

                    </CardContent>
                    
                </Card>
            </Fade>
        </Grid>
    );
};

export default ApplicationCard;
