import React from "react";
// import { Link, useLocation } from "react-router-dom";
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

// import { routeMatches } from "../../utils";

const LayoutMenuItem = ({ icon: Icon, label, handleClick , disabled,selected }) => {
//   const { pathname } = useLocation();
const handleItemClick = () => {
    // Manejar el clic en el elemento del menú
    if (handleClick) {
      handleClick();
    }
  };

  return (
    <ListItem disablePadding onClick={handleItemClick}
    selected={selected}
    >
      <ListItemButton 
    //   component={Link} to={to}
      
      disabled={disabled}>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText>{label}</ListItemText>
      </ListItemButton>
    </ListItem>
  );
};

export default LayoutMenuItem;
