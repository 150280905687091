import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';

const Footer: React.FC = () => {
    return (
        <Box sx={{ backgroundColor: '#1a1a3d', paddingY: 4, textAlign: 'center' }}>
            <Typography variant="h6">
                Tenemos la solución de automatización para ti, agenda cita en:
            </Typography>
            <Box display="flex" justifyContent="center" alignItems="center" marginTop={1}>
                <IconButton href="tel:+524431211679">
                    <PhoneIcon sx={{ color: 'white' }} />
                </IconButton>
                <Typography variant="h6" sx={{ marginLeft: 1 }}>
                    +52 (44) 31 21 16 79
                </Typography>
            </Box>
            <Typography variant="body1" mt={1}>iotayudo.com</Typography>
        </Box>
    );
};

export default Footer;
