import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, CircularProgress } from '@mui/material';

const ChatGPT = ({ chartData }) => {
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [animatedResponse, setAnimatedResponse] = useState('');
  useEffect(() => {
    const fetchOpenAIResponse = async () => {
        if (!chartData || chartData.length === 0) return;        
      setLoading(true);
      setError('');
      try {
        // alert(currentResponse);
        const result = await axios.post(
          'https://api.openai.com/v1/chat/completions',
          {
            model: 'gpt-3.5-turbo',
            messages: [{ role: 'user', content: "HOLA CHAT: tengo estos datos,COMENTAME LA TENDENCIA QUE TIENEN Y CARACTERISTICAS DE FORMA:"+ JSON.stringify(chartData)}],
            temperature: 0.7,
          },
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
              'Content-Type': 'application/json',
            },
          }
        );
        setResponse(result.data.choices[0].message.content);
      } catch (error) {
        setError('Error fetching data from OpenAI: ' + error.message);
      } finally {
        setLoading(false);
      }
    };
    // if (!chartData) return;

    fetchOpenAIResponse();
  }, [chartData]);

  useEffect(() => {
    let index = 0;
    // let animationFrameId;
    let animationTimeoutId;
    let accumulatedResponse = ''; // Variable para acumular el texto

    const displayText = () => {
      if (index < response.length) {
        accumulatedResponse += response[index]; // Acumula el texto en la variable
        setAnimatedResponse(accumulatedResponse); // Actualiza el estado con el texto acumulado
        index++;
        // animationFrameId = requestAnimationFrame(displayText);
        animationTimeoutId = setTimeout(displayText, 5); // Ajusta la velocidad de la animación
      }
    };

    if (response) {
      setAnimatedResponse(''); // Resetea animatedResponse antes de empezar una nueva animación
    //   cancelAnimationFrame(animationFrameId); // Cancela cualquier animación previa
    clearTimeout(animationTimeoutId); // Cancela cualquier timeout previo
    displayText();

    }

    // return () => cancelAnimationFrame(animationFrameId); // Limpieza al desmontar o cambiar response
    return () => clearTimeout(animationTimeoutId); // Limpieza al desmontar o cambiar response
  }, [response]);

  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, p: 2 }}>
      <Typography variant="h5" gutterBottom>
        Respuesta de ChatGPT
      </Typography>
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        <>
          {error && <Typography color="error">{error}</Typography>}
          {animatedResponse && (
            <Box sx={{ mt: 2, p: 2, border: '1px solid #ccc', borderRadius: '4px', width: '100%' }}>
              <Typography variant="h6">Respuesta:</Typography>
              <Typography>{animatedResponse}</Typography>
              {/* <Typography variant="h6">Original:</Typography>
              <Typography>{response}</Typography> */}
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default ChatGPT;
