import React, { useRef, useEffect, useState } from 'react';
import { Container, Typography, Grid, Box } from '@mui/material';
import { Fade } from '@mui/material';
import { useInView } from 'react-intersection-observer';

const DirectActionsSection: React.FC = () => {
    // const [animated, setAnimated] = useState(false);  

    // const listenToScroll = () => {
    //   const winScroll =
    //     document.body.scrollTop || document.documentElement.scrollTop;
  
    //   const height =
    //     document.documentElement.scrollHeight - document.documentElement.clientHeight;
  
    //   const scrolled = winScroll / height;
    //   if (scrolled > 0.675426 && scrolled < 0.87543246) {
    //     setAnimated(true);
    //   } else { 
    //     setAnimated(false);
    //   }
    // };
  
    // useEffect(() => {
    //   window.addEventListener('scroll', listenToScroll);
  
    //   return () => {
    //     window.removeEventListener('scroll', listenToScroll);
    //   };
    // }, []);
    const { ref, inView } = useInView({
        triggerOnce: false, // Solo activa la animación una vez
        threshold: 0.2, // 20% del elemento visible para activar
    });
    
    return (
        <Container
            sx={{
                paddingY: 5,
                maxWidth: {
                    xs: '100%', 
                    sm: '100%', 
                    md: '100%',    
                    lg: '100%',    
                    xl: '2000px',    
                },
            }}
        >
            <Typography variant="h3" align="center" gutterBottom marginBottom={5}>
                Configuración de acciones directas y programadas
            </Typography>
            <Grid container spacing={4} alignItems="center" ref={ref}>
                
                {/* Imagen en el lado derecho */}
                <Fade  in={inView} timeout={1000}>

                <Grid item xs={12} md={6}
                    // className={`animate__animated ${animated ? 'animate__lightSpeedInLeft' : ''}`}  // Agrega clase de animación al ser visible
                    >
                    
                    <Box
                        component="img"
                        src="/images/desktop2.jpg"
                        alt="Ejemplo de instalación o beneficios"
                        sx={{
                            width: '100%',
                            height: 'auto',
                            borderRadius: 3,
                            objectFit: 'cover',
                        }}
                    />
                </Grid>
                </Fade>


                <Fade in={inView} timeout={1000}>
                <Grid item xs={12} md={6}
                    // className={`animate__animated ${animated ? 'animate__lightSpeedInRight' : ''}`}  // Agrega clase de animación al ser visible
                    >
                    <Box
                        component="img"
                        src="/images/desktop3.jpg"
                        alt="Ejemplo de instalación o beneficios"
                        sx={{
                            width: '100%',
                            height: 'auto',
                            borderRadius: 3,
                            objectFit: 'cover',
                        }}
                    />
                </Grid>
                </Fade>


            </Grid>
        </Container>
    );
};

export default DirectActionsSection;
