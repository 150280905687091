import React from 'react';
import { Box, Container, Typography, Grid } from '@mui/material';


import HomeIcon from '@mui/icons-material/Home';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import PowerIcon from '@mui/icons-material/Power';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import WaterIcon from '@mui/icons-material/Water';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';

import ApplicationCard from './ApplicationCard';

const applications =     [
    {
        icon: <HomeIcon sx={{ color: 'primary.main' }} />,
        title: "Monitoreo de Energía en el Hogar",
        description: "Controla el consumo energético en tiempo real y optimiza el uso en tu hogar.",
        imageUrl: '/images/hogar.jpg',
    },
    {
        icon: <AgricultureIcon sx={{ color: 'primary.main' }} />,
        title: "Cultivo Indoor",
        description: "Automatiza y monitorea las condiciones ideales para cultivos interiores.",
        imageUrl: '/images/indoor.jpg',
    },
    {
        icon: <SolarPowerIcon  sx={{ color: 'primary.main' }} />,
        title: "Energías Renovables",
        description: "Gestiona paneles solares y otros sistemas de energía renovable.",
        imageUrl: '/images/back3.jpg',
    },
    {
        icon: <WaterIcon sx={{ color: 'primary.main' }} />,
        title: "Gestión de Agua",
        description: "Supervisa el nivel de agua en tinacos y aljibes y controla el bombeo automáticamente.",
        imageUrl: '/images/level3.jpg',
    },
    {
        icon: <LocalFloristIcon sx={{ color: 'primary.main' }} />,
        title: "Jardinería y Riego",
        description: "Monitorea y controla el riego de jardines para mantener plantas saludables.",
        imageUrl: '/images/outdoor.jpg',
    },
    {
        icon: <EmojiObjectsIcon sx={{ color: 'primary.main' }} />,
        title: "Lámparas y otros",
        description: "Enciende y apaga cualquier actuador para encendido o intensidad de lamparas, luces led y más.",
        imageUrl: '/images/level.png',
    },
]

const ApplicationAreas: React.FC = () => {
    return (
        <Box sx={{ backgroundColor: '#0d0d25', color: 'white', paddingY: 5 }}>
        <Container sx={{maxWidth:{
            xs: '100%', // en pantallas extra pequeñas
            sm: '100%', // en pantallas pequeñas
            md: '100%',    // en pantallas medianas
            lg: '100%',    // en pantallas grandes
            xl: '2000px',    // en pantallas extra grandes
            }, }}>
            <Typography variant="h3" align="center" gutterBottom>
                Otras áreas de Aplicación
            </Typography>

            <Typography variant="body1" align="center" sx={{ marginBottom: 4 }}>
                Nuestra plataforma ofrece soluciones de monitoreo y automatización en diversos ámbitos.
            </Typography>

            <Grid container spacing={4}>
                {applications.map((application, index) => (
                    <ApplicationCard key={index} application={application} />
                ))}
            </Grid>
        </Container>

    </Box>
    );
};

export default ApplicationAreas;
