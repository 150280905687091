import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import Login from './login.tsx';
// import UserManagement from './UserManagement.tsx';
import reportWebVitals from './reportWebVitals';
import { SnackbarProvider } from 'notistack';
import { Box } from '@mui/material';
import CustomTheme from './CustomTheme';
import Main from './other/main';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CustomTheme>

<Box
        sx={{
          maxWidth: '100%', // Ancho máximo de 1400px
          margin: '0 auto', // Centrar en la pantalla
          padding: '0 20px', // Un pequeño padding para los márgenes laterales en pantallas pequeñas
        }}
      >

<SnackbarProvider maxSnack={3}>  {/* Configura el número máximo de snackbars visibles */}
      <App />
    </SnackbarProvider>
    
      </Box>
      </CustomTheme>

      </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
