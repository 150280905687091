import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';

const WebSocketMqttClient = () => {
  const [topic, setTopic] = useState('REQUEST/esp32-08d1f9e7f0d8');
  const [message, setMessage] = useState('showmeyougot');
  const [response, setResponse] = useState(null);
  const [ws, setWs] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  // Establecer la conexión WebSocket cuando el componente se monta
  useEffect(() => {
    // const socket = new WebSocket('ws://localhost/websockets:8080');
    
    const socket = new WebSocket("wss://iotayudo.com:8884/ws/");
    
    // wss://2807ccce5f924766a34afeab1eb54217.s2.eu.hivemq.cloud:8884/mqtt
    socket.onopen = () => {
      console.log('WebSocket connection established');
      enqueueSnackbar('WebSocket connection established', { variant: 'success' });

      setWs(socket);
    };

    socket.onmessage = (event) => {
      console.log('Received response:', event.data);
      enqueueSnackbar('Received response', { variant: 'success' });

      setResponse(event.data);  // Guardar la respuesta en el estado
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
      enqueueSnackbar('WebSocket error '+error, { variant: 'error' });

    };

    return () => {
      socket.close();
    };
  }, []);

  const handlePublish = () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      // Enviar el topic y mensaje al backend WebSocket
      ws.send(JSON.stringify({ topic, message }));
      enqueueSnackbar('Enviar el topic y mensaje ', { variant: 'success' });

    }
  };

  return (
    <div>
      <h1>WebSocket MQTT Client</h1>
      <input 
        type="text" 
        placeholder="Topic" 
        value={topic}
        onChange={(e) => setTopic(e.target.value)} 
      />
      <textarea 
        placeholder="Message" 
        value={message}
        onChange={(e) => setMessage(e.target.value)} 
      />
      <button onClick={handlePublish}>Publish</button>

      {response && <div>Response: {response}</div>}
    </div>
  );
};

export default WebSocketMqttClient;
