import { FC, useContext, useEffect,useState} from 'react';
import React  from 'react';
import { Switch, Button, IconButton, Table, TableBody, TableCell, TableFooter, TableHead, TableRow  , Theme, useTheme } from "@mui/material";
// import './Peripheric.css';
import ToggleSwitch from './ToggleSwitch';
const Peripheric= ({perif, handlePublish,currentDevice})=> {

  const handleSubmit = () => {
    const jsonData = {
      peripheric: perif.idx, // Utilizar perif.idx como valor de peripheric
      state: !perif.state // Utilizar perif.state como valor de state
    };
    const jsonString = JSON.stringify(jsonData);
    // alert("REQUEST/" + currentDevice+jsonString)
    handlePublish("REQUEST/" + currentDevice, jsonString);
  };

    const content = () => {             
        return (
          /*perif.EnGpio&&*/
          <TableRow >
          <TableCell component="th" scope="row" sx={{ textAlign:"center" }}>
                {perif.io === "IN" ? (
                  <img src={'../images/input.png'} alt="IN" style={{ width: '30px', height: '30px' }} />
                ) : (
                  <img src={'../images/output.png'} alt="OUT" style={{ width: '30px', height: '30px' }} />
                )}
           </TableCell>
                
                {perif.io==="OUT"?
                <TableCell align="center">
                    <Switch
                      name="led_on"
                      color="primary"
                      checked={perif?(!(perif.state==0))?true:false:false}
                      onClick={()=>handleSubmit()}
                    />{String(perif.GpIOTyp)}



                </TableCell>:
                <TableCell align="center">                    
                    
                    <ToggleSwitch disabled={true} initialChecked={true} onLabel={perif.state?"ON":"OFF"} offLabel={perif.state} />

                    {/* {
                    (perif.GpIOTyp===13)?perif.state:                    
                    (perif.state?"CONECTADO "+perif.state:"FUERA DE LINEA "+perif.state)
                    } */}
                    
                </TableCell>
                }

                {/*<TableCell align="center">
                    <div>{perif.GpIOTyp}</div>
                </TableCell>
                <TableCell align="center">
                    <div>{perif.IDper}</div>
                </TableCell>*/}
                
                <TableCell align="center">
                    <div>{perif.IDName}</div>
                </TableCell>
                 {/*<TableCell align="center">
                    <div>{perif.PinOut}</div>
                </TableCell>
                <TableCell align="center">
                    <div>{perif.idx}</div>
                </TableCell>*/}                 
              </TableRow>             
        );
      };                
  return (
    < >
      {content()}
    </>
  );               
};      


export default Peripheric;