import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import config from './config.json';
import { Link } from 'react-router-dom'; // Asegúrate de tener react-router-dom instalado y configurado
const Header: React.FC = () => {
  return (
    <AppBar sx={{ backgroundColor: '#1e1e1e' }}>
      <Toolbar>
        {/* Contenedor para la imagen del favicon y el nombre de la compañía */}
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          {/* Imagen del favicon */}
          <Box
            component="img"
            src="/favicon.png" // Ruta del favicon en tu carpeta pública
            alt="favicon"
            sx={{
              width: '30px', // Ajusta el tamaño de la imagen
              height: '30px',
              marginRight: '10px', // Espacio entre el icono y el texto
            }}
          />
          {/* Nombre de la compañía */}
          <Typography
            variant="h6"
            sx={{ fontFamily: 'Orbitron, sans-serif' }}
          >
            {config.header.companyName}
          </Typography>
        </Box>

        {/* Menú de navegación */}
        {config.header.menu.map((item, index) => (
          <Button
            key={index}
            color="inherit"
            component={Link}
            to={item.link} // Enlace hacia la ruta correspondiente
          >
            {item.name} {/* Nombre del enlace */}
          </Button>
        ))}
      </Toolbar>
    </AppBar>
  );
};

export default Header;

