import { FC, useEffect, useState } from 'react';
import { TableRow, TableCell, Switch } from "@mui/material";
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';

import OutputIcon from '@mui/icons-material/Outbox';
import InputIcon from '@mui/icons-material/MoveToInbox';
// Define el tipo perifType basado en los datos que recibes en tu proyecto
type perifType = {
  EnGpio: boolean;
  GpIOTyp: number;
  IDName: string;  
  state: number;
  IDper: string;
  PinOut: number;
  io: string;
  idx: number;
};

interface perifProps {
  perif: perifType;
  qrcode: string;  // Añado esto para pasar el qrcode al manejar los periféricos
}

const Peripheric: FC<perifProps> = ({ perif, qrcode }) => {
  const [myState, setMyState] = useState(perif.state !== 0);

  // Función para enviar los cambios en el estado del periférico
  const handleSubmit = async () => {
    const messageData = {
      peripheric: perif.idx,
      state: myState ? 0 : 1,
    };
  

    try {
      const response = await axios.post(
        `http://localhost/reactlogin/requestservicemqtt.php`,
        {
          qr_code: qrcode,
          topic: `REQUEST/${qrcode}`,
          message: JSON.stringify(messageData), // Cambia el mensaje a lo que necesites
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      const { message, response: serviceResponse } = response.data;

        if(message==="Waiting for response"){
          
            enqueueSnackbar(serviceResponse, { variant: 'warning' });                
        }else{
            enqueueSnackbar(message, { variant: 'success' });
            // setData(message);
      
        }
      enqueueSnackbar(message, { variant: 'success' });
      setMyState(!myState);  // Cambia el estado local tras el éxito
    } catch (error) {
      enqueueSnackbar('Error al actualizar el estado', { variant: 'error' });
    }
  };

  useEffect(() => {
    setMyState(perif.state !== 0); // Actualiza el estado inicial basado en los datos del periférico
  }, [perif.state]);

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {/* {perif.io}  */}
        {/* Campo que representa si es INPUT o OUTPUT */}

        {perif.io === "OUT" ? (
          <OutputIcon />
        ) : (
          <InputIcon />
        )}
        
      </TableCell>

      <TableCell align="center">
        {perif.io === "OUT" ? (
          <Switch
            name="led_on"
            color="primary"
            checked={myState}
            onClick={handleSubmit}  // Envía los datos al backend cuando el switch cambia
          />
        ) : (
          perif.state !== 0 ? `CONECTADO ${perif.state}` : `FUERA DE LINEA ${perif.state}`
        )}
      </TableCell>

      <TableCell align="center">
        {perif.IDName} {/* Nombre del periférico */}
      </TableCell>
    </TableRow>
  );
};

export default Peripheric;
