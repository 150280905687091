
import React, { useState,useEffect } from 'react';
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
// import { FeaturesContext } from '../../contexts/features';
import LayoutAuthMenu from './LayoutAuthMenu';
// import { Led } from 'steelseries';
import "steelseries"

export const DRAWER_WIDTH = 280;


const LayoutAppBar = ({ title, onToggleDrawer,connected,blinkState = false,blinkStateMq }) => {
  // const { features } = useContext(FeaturesContext);
  // const [parppadeo, setparppadeo] = useState(true);

  // const [online, setonline] = useState(false);

  // Función para alternar el estado de parpadeo
  // const toggleBlink = () => {
  //   if(connected){
  //     setparppadeo(prevBlink => !prevBlink);
  //   }else{
  //     setparppadeo(false);
  //   }
  //   // console.log( parppadeo)

    
  // };
  // useEffect(() => {
  //   // console.log("El estado de parppadeo ha cambiado:", parppadeo);
  //   // Aquí puedes realizar acciones adicionales basadas en el cambio de parppadeo
  // }, [parppadeo]); // Esta dependencia indica que el efecto se ejecutará cada vez que parppadeo cambie


  // useEffect(() => {
  //   // const interval = setInterval(toggleBlink, 1000);
  //   // return () => clearInterval(interval);
  // }, []);

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { md: `calc(100% - ${DRAWER_WIDTH}px)` },
        ml: { md: `${DRAWER_WIDTH}px` },
        boxShadow: 'none'
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={onToggleDrawer}
          sx={{ mr: 2, display: { md: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <p>
         
         {connected ? "SERVER CONNECTED" : "DISCONNECTED"}
        <steelseries-led
          size={20} 
          // ledColor={"BLUE_LED"}
          {...(blinkState ? {ledOn: true} : {})}
          />

          {connected ? "DEVICE" : "OFFLINE"}        
        <steelseries-led
          size={20} 
          ledColor={"BLUE_LED"}
          {...((blinkStateMq) ? {ledOn: true} : {})}
          />

          </p>
        <Box flexGrow={1} />
        {/* {features.security && 
        } */}


       
                     <LayoutAuthMenu />


      </Toolbar>
    </AppBar>
  );
};

export default LayoutAppBar;
