import React, { useState, useEffect } from 'react';
import {
  TextField, Button, Typography, Card, CardContent, IconButton, Grid,
  CardMedia, CircularProgress, Modal, Box
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MonitorIcon from '@mui/icons-material/Monitor';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import GlitchImage from "./GlitchImage";
import QrScanner from 'qr-scanner'; // Importa la librería QR Scanner
import ForceDirectedTreeComponent from './ForceDirectedTreeComponent';

import imageUrl from './back2.png';  // Background image
import imageUrl2 from './av.png';     // Device image
import WaterTankLevel from "./WaterTankLevel";
const DeviceManagement = () => {
  const [devices, setDevices] = useState([]);
  const [newDevice, setNewDevice] = useState({ model: '', name: '', ctd_perif: '', qr_code: '' });
  const [token, setToken] = useState('');
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  const storedToken = localStorage.getItem('token');
  const storedUserId = localStorage.getItem('user_id');
  
  const [selectedFile, setSelectedFile] = useState(null);
  const [qrScanning, setQrScanning] = useState(false);

  // Maneja el escaneo de códigos QR desde la imagen
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      scanQrCode(file); // Llama a la función de escaneo cuando se selecciona una imagen
    }
  };

  // Escanea el código QR en la imagen seleccionada
  const scanQrCode = async (file) => {
    setQrScanning(true);
    try {
      const result = await QrScanner.scanImage(file);
      if (result) {
        setNewDevice({ ...newDevice, qr_code: result });
        enqueueSnackbar('QR Code detected!', { variant: 'success' });
      } else {
        enqueueSnackbar('No QR Code detected', { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar('Error scanning QR code', { variant: 'error' });
    } finally {
      setQrScanning(false);
    }
  };



  useEffect(() => {
    if (!storedToken) {
      // Redirigir al login si no hay token o user_id
      // navigate('/login');
    } else {
      setToken(storedToken);
      setUserId(storedUserId);
      fetchDevices(storedToken, storedUserId); // Cargar los dispositivos del usuario
    }
  }, [navigate]);

  const fetchDevices = async (jwtToken, userId) => {
    setLoading(true);
    try {
      const response = await axios.get(`https://iotayudo.com/wstomqtt/devices.php`, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
      const { message } = response.data;
      if (message === "No devices found for this user.") {
        enqueueSnackbar(message, { variant: 'success' });
      } else {
        setDevices(response.data);
      }
      setLoading(false);
    } catch (error) {
      enqueueSnackbar('Failed to fetch devices', { variant: 'error' });
      setLoading(false);
    }
  };

  const addDevice = async () => {
    if (!newDevice.model || !newDevice.name || !newDevice.ctd_perif) {
      enqueueSnackbar('All fields are required', { variant: 'warning' });
      return;
    }

    try {
      const response = await axios.post(
        'https://iotayudo.com/wstomqtt/devices.php',
        {
          user_id: userId,
          model: newDevice.model,
          name: newDevice.name,
          ctd_perif: parseInt(newDevice.ctd_perif),
          qr_code: newDevice.qr_code,
        },
        {
          headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
        }
      );

      enqueueSnackbar('Device added successfully', { variant: 'success' });
      fetchDevices(storedToken, storedUserId);
      setNewDevice({ model: '', name: '', ctd_perif: '', qr_code: '' });
      setOpenModal(false);
    } catch (error) {
      enqueueSnackbar('Failed to add device', { variant: 'error' });
    }
  };

  const deleteDevice = async (deviceId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this device?");
  
  if (!isConfirmed) return; // Si el usuario cancela, se sale de la función

    try {
      await axios.delete(`https://iotayudo.com/wstomqtt/devices.php?device_id=${deviceId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      enqueueSnackbar('Device deleted successfully', { variant: 'success' });
      setDevices(devices.filter((device) => device.id !== deviceId));
    } catch (error) {
      enqueueSnackbar('Failed to delete device', { variant: 'error' });
    }
  };

  const handleMonitorClick = (qr_code) => {
    navigate(`/Dashboard/${qr_code}`);
  };
  const handleSignOut = () => {
    localStorage.removeItem('token');
    navigate('/');
  };
  return (
    <div style={{ padding: '20px', backgroundColor: '#121212', minHeight: '100vh' }}>
      <Typography variant="h4" color="primary" gutterBottom>
        IoT Device Management Dashboard
      </Typography>

      <Button variant="outlined" color="error" onClick={handleSignOut} sx={{ alignSelf: 'flex-end', mb: 2 }}>
        Sign Out
      </Button>


      {/* Modal for adding device */}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="add-device-modal"
        aria-describedby="add-device-form"
      >
        <Box sx={modalStyle}>
          <Typography id="add-device-modal" variant="h6" gutterBottom>
            Add a New Device
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Model"
                value={newDevice.model}
                onChange={(e) => setNewDevice({ ...newDevice, model: e.target.value })}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Name"
                value={newDevice.name}
                onChange={(e) => setNewDevice({ ...newDevice, name: e.target.value })}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Peripheral Count"
                value={newDevice.ctd_perif}
                onChange={(e) => setNewDevice({ ...newDevice, ctd_perif: e.target.value })}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="QR Code (optional)"
                value={newDevice.qr_code}
                onChange={(e) => setNewDevice({ ...newDevice, qr_code: e.target.value })}
                fullWidth
                margin="normal"
              />
              <input
                accept="image/*"
                type="file"
                onChange={handleFileChange}
                style={{ marginTop: '10px' }}
              />
              {qrScanning && <CircularProgress />}
            </Grid>
          </Grid>
          <Button variant="contained" color="primary" onClick={addDevice} style={{ marginTop: '20px' }}>
            Add Device
          </Button>
        </Box>
      </Modal>



      {loading ? (
        <CircularProgress color="secondary" />
      ) : (
        <Grid container spacing={3}>
                  <ForceDirectedTreeComponent HDM={devices} />

          {devices.map((device) => (
            <Grid item xs={12} sm={6} md={4} key={device.id}>
              <Card style={{ backgroundColor: '#1e1e1e', color: '#fff' }}>
                <CardMedia
                  component="img"
                  height="140"
                  image={imageUrl2} // Replace with device image
                  alt={device.name}
                />
                <CardContent>
                  <Typography variant="h6" color="primary">{device.name}</Typography>
                  <Typography variant="body2" color="textSecondary">Model: {device.model}</Typography>
                  <Typography variant="body2" color="textSecondary">Peripherals: {device.ctd_perif}</Typography>
                  <Typography variant="body2" color="textSecondary">QR Code: {device.qr_code || 'N/A'}</Typography>
                  {/* <WaterTankLevel /> */}
                  <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
                    <IconButton onClick={() => deleteDevice(device.id)} aria-label="delete" color="error" 
                      >

                      <DeleteIcon />
                    </IconButton>
                    <IconButton onClick={() => handleMonitorClick(device.qr_code)} aria-label="monitor" color="primary">
                      <MonitorIcon style={{ width: "2em",
                      height: "2em" }}/>
                    </IconButton>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        
      )}
      
      <Typography variant="h5" color="primary" style={{ marginTop: '40px', marginBottom: '20px' }}>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<AddCircleOutlineIcon />}
        onClick={() => setOpenModal(true)}
        style={{ marginBottom: '20px' }}
      >
        Add New Device
      </Button>
      </Typography>

      
    </div>
  );
};

export default DeviceManagement;

// Estilos del modal
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
