import React, { useRef, useEffect, useState } from 'react';
import { Container, Typography, Grid, List, ListItem, ListItemIcon, ListItemText, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Fade  } from '@mui/material';
import { useInView } from 'react-intersection-observer';

const BenefitsSection: React.FC = () => {
//     const [animated, setAnimated] = useState('');  

//   const listenToScroll = () => {
//     const winScroll =
//       document.body.scrollTop || document.documentElement.scrollTop;

//     const height =
//       document.documentElement.scrollHeight - document.documentElement.clientHeight;

//     const scrolled = winScroll / height;
//     if (scrolled > 0.26 && scrolled < 0.43246) {
//       setAnimated('animate__animated animate__slideInRight animate__delay-.5s');
//     } else { 
//       setAnimated('');
//     }
//   };

//   useEffect(() => {
//     window.addEventListener('scroll', listenToScroll);

//     return () => {
//       window.removeEventListener('scroll', listenToScroll);
//     };
//   }, []);
const { ref, inView } = useInView({
    triggerOnce: false, // Solo activa la animación una vez
    threshold: 0.2, // 20% del elemento visible para activar
});

    return (
        <Container
            sx={{
                paddingY: 5,
                maxWidth: {
                    xs: '100%', // en pantallas extra pequeñas
                    sm: '100%', // en pantallas pequeñas
                    md: '100%', // en pantallas medianas
                    lg: '100%', // en pantallas grandes
                    xl: '2000px', // en pantallas extra grandes
                },
            }}
        >
            <Typography variant="h3" align="center" gutterBottom marginBottom={5}>
                Todos los Paquetes Incluyen:
            </Typography>
            <Grid container spacing={4} alignItems="center"       ref={ref}
            >
                
                {/* Lista de Beneficios */}
                <Grid item xs={12} md={6}>
                    <List>
                        {[
                            "Monitoreo completo dentro de tu red WiFi",
                            "Monitoreo desde plataforma",
                            "Notificaciones y servicios avanzados económicos"
                        ].map((benefit, index) => (
                            <ListItem key={index}>
                                <ListItemIcon>
                                    <CheckCircleIcon sx={{ color: 'primary.main' }} />
                                </ListItemIcon>
                                <Typography
                                        sx={{
                                        fontSize: {
                                            xs: '1.2rem',  // Extra pequeñas
                                            sm: '1.3rem',  // Pequeñas
                                            md: '1.2rem',  // Medianas
                                            lg: '1.5rem',  // Grandes
                                            xl: '2rem',  // Extra grandes
                                        },
                                        }}
                                    >
                                        {benefit}
                                    </Typography>
                            </ListItem>
                        ))}

                        {[
                            "Vinculación con ALEXA amazon (1 comando/device)",
                            "Implementación avanzada de ChatGpt para interpretar comportamientos de su sistemas"
                        ].map((benefit, index) => (
                            <ListItem key={index + 3}>
                                <ListItemIcon>
                                    <CheckCircleIcon sx={{ color: 'green' }} />
                                </ListItemIcon>
                                <Typography
                                        sx={{
                                        fontSize: {
                                            xs: '1.2rem',  // Extra pequeñas
                                            sm: '1.3rem',  // Pequeñas
                                            md: '1.2rem',  // Medianas
                                            lg: '1.5rem',  // Grandes
                                            xl: '2rem',  // Extra grandes
                                        },
                                        }}
                                    >
                                        {benefit}
                                    </Typography>
                            </ListItem>
                        ))}
                    </List>
                </Grid>

                <Fade in={inView} timeout={1000}>
                {/* Imagen en el lado derecho */}
                <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
                    <Box 
                            // className={`${animated}` }        

                        component="img"
                        src="/images/level4.jpg"
                        alt="Ejemplo de instalación o beneficios"
                        sx={{
                            width: '80%',
                            height: 'auto',
                            borderRadius: 3,
                            objectFit: 'cover',
                        }}
                    />
                </Grid>
                </Fade>

            </Grid>
        </Container>
    );
};

export default BenefitsSection;
