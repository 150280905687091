import React, { useState, useEffect } from 'react';
// import { useLayoutTitle } from '../../components';
import { VictoryChart, VictoryLine, VictoryAxis, VictoryTooltip, VictoryLabel, VictoryContainer } from 'victory';
// import { FormLoader, SectionContent } from '../components';
// import { useRestArg } from '../../utils/useRestArg';
// import { generateChartData, generateChartDataH, generateChartData24H } from './chartHelpers'; // asumo que estas funciones están definidas en un archivo separado
import ChatGPT from './ChatGPT'; // Importa el componente

const VictoryLineChart = ({ sensor, dataPeer,currentResponse }) => {
  

  const [chartData, setChartData] = useState([]);
  const [units, setUnits] = useState("adim");
  const [ydomain, setYdomain] = useState(1);
  const [ymindomain, setMinYdomain] = useState(1);

  const [data, setData] = useState(null);  

  // useLayoutTitle("Gráficas de datos");

  useEffect(() => {
    // loadData(sensor, dataPer);
  }, [sensor, dataPeer]);


  
  useEffect(() => {
    try {
      setData(currentResponse);
      console.log('setData to:'+currentResponse);
    } catch (error) {
      console.error('Error al parsear JSON:', error);
      setData(null); // Establecer data como null si hay un error al parsear JSON
    }
  }, [currentResponse]);


  useEffect(() => {
    if (data && data.readings) {

      // console.log(data.readings);
      // setChartData(data.readings);
      // return;
      // var dts = JSON.stringify(data, null, 2);
      // const max = JSON.parse(dts).max;
      // const min = JSON.parse(dts).min;
      // const obj = JSON.parse(dts);
      // var dts = JSON.stringify(data, null, 2);
      // const obj = data;
      const max = data.max;
      const min = data.min;


      // setUnits(data.units);
      setUnits("adim");

      if (dataPeer === "minuts") {
        setChartData(generateChartData(data.readings));
      } else if (dataPeer === "hours") {
        setChartData(generateChartDataH(data.readings));
      } else if (dataPeer === "24h") {
        setChartData(generateChartData24H(data.readings));
      }

      const maxY = Math.max(...data.readings);
      if (maxY <= 1) {
        setYdomain(1);
      } else {
        setYdomain(1.2 * maxY);
      }

      const minY = Math.min(...data.readings);
      if (minY <= 0) {
        setMinYdomain(minY * 1.2);
      } else {
        setMinYdomain(0);
      }
    }else{
      setChartData([]);
    }
  }, [data]);


  const generateChartData = (readings) => {
    const n = readings.length;
    const tiempoPrimerGrafica = (n * 10) / 60;
    const fechaActual = new Date();
  
    const data = readings.map((reading, i) => {
      const tiempo = fechaActual.getTime() - i * 5 * 1000;
      const fecha = new Date(tiempo);
      const hora = fecha.getHours();
      const minutos = fecha.getMinutes();
      const segundos = fecha.getSeconds();
  
      return {
        x: `${hora}:${minutos < 10 ? '0' : ''}${minutos}:${segundos < 10 ? '0' : ''}${segundos}`,
        y: reading,
      };
    });
  
    return data;
  };
  
  const generateChartDataH = (readings) => {
    const n = readings.length;
    const fechaActual = new Date();
  
    const data = readings.map((reading, i) => {
      const tiempo = fechaActual.getTime() - i * (5 * 1000) * 60;
      const fecha = new Date(tiempo);
      const hora = fecha.getHours();
      const minutos = fecha.getMinutes();
  
      return {
        x: `${hora}:${minutos < 10 ? '0' : ''}${minutos}`,
        y: reading,
      };
    });
  
    return data;
  };
  
  const generateChartData24H = (readings) => {
    const n = readings.length;
    const fechaActual = new Date();
  
    const data = readings.map((reading, i) => {
      const tiempo = fechaActual.getTime() - i * (1000) * 60 * 60;
      const fecha = new Date(tiempo);
      const hora = fecha.getHours();
      const minutos = fecha.getMinutes();
  
      return {
        x: `${hora}:${minutos < 10 ? '0' : ''}${minutos}`,
        y: reading,
      };
    });
  
    return data;
  };
  

  const content = () => {
    if (!data) {
      // return <FormLoader />;
    }

    return (
      <div
      className="containerGraph"
      style={{
        width: '95%',
        maxWidth: '600px',
        margin: '0 auto',
        textAlign: 'center',
        backgroundColor: '#121212', // Fondo oscuro del gráfico
        padding: '20px',
        borderRadius: '8px',
      }}
    >
      <VictoryChart
        width={600}
        height={400}
        containerComponent={<VictoryContainer responsive={true} />}
        padding={{ top: 50, bottom: 80, left: 80, right: 20 }}
        style={{
          background: { fill: '#121212' }, // Fondo del contenedor de Victory
        }}
      >
        <VictoryAxis
          tickCount={dataPeer === '24h' ? 12 : 7}
          style={{
            axis: { stroke: '#ffffff' }, // Color del eje
            tickLabels: { fontSize: 10, fill: '#d0d7df' }, // Color de las etiquetas de los ticks
            axisLabel: { fontSize: 12, padding: 30, fill: '#d0d7df' }, // Color de la etiqueta del eje
          }}
          label="Tiempo (hh/mm/ss)"
        />
        <VictoryAxis
          dependentAxis
          label={units}
          style={{
            axis: { stroke: '#ffffff' },
            tickLabels: { fontSize: 10, fill: '#d0d7df' },
            axisLabel: { fontSize: 12, padding: 60, fill: '#d0d7df' },
          }}
          domain={{ y: [ymindomain, ydomain] }}
        />
        <VictoryLine
          data={chartData}
          x="x"
          y="y"
          style={{
            data: { stroke: '#8884d8' },
          }}
          labelComponent={<VictoryTooltip style={{ fontSize: 10, fill: '#d0d7df', backgroundColor: '#333' }} />}
        />
        <VictoryLabel
          text={'Historial de los últimos ' + dataPeer}
          x={300}
          y={50}
          textAnchor="middle"
          style={{ fontSize: 16, fill: '#ffffff' }} // Color del título
        />
      </VictoryChart>
    </div>
    
    );
  };

  return (
    <div>
      {content()}
      <ChatGPT chartData={chartData} /> {/* Añade el componente ChatGPT aquí */}
    </div>
  );
};

export default VictoryLineChart;
