import React, { FC, useState, useEffect } from 'react';


import { Box, Tab, Typography,Tabs,AppBar,Grid  } from '@mui/material';

import ReChart from './VictoryLineChart';
import WaterJar from './WaterJar';


const TabChart = ({ sensor,dataPeer,setDataPeer,eventCheckselectedItem,
   currentResponse,setCurrentResponse,handlePublish,currentDevice,waiting,setWaiting}) => {
    const [selectedTab, setSelectedTab] = useState('minuts'); // Estado para la pestaña seleccionada
    
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
      console.log("selectedTab "+newValue);

      setCurrentResponse(null);

              
      switch (newValue) {
        case 0:
          setSelectedTab('minuts');
          setDataPeer('minuts');
          break ;
        case 1:
          setSelectedTab('hours');
          setDataPeer('hours');

          break;
        case 2:
          setSelectedTab('24h');
          setDataPeer('24h');

          break;
        case 3:
          // return <ChartSettings sensor={sensor} />;
        default:
          return null;
      }
      
     

    };

    useEffect(() => {
      // const getGraph="{\"path\": \"\graph\",\"sensor\": "+sensor+",\"dataspeer\": \""+selectedTab+"\"}";
      // const jsonData = {
      //   path: "\graph",
      //   sensor:sensor,
      //   dataspeer:selectedTab
      // };
      // const jsonString = JSON.stringify(jsonData);
      // handlePublish("REQUEST/" + currentDevice, jsonString); // Llamar a handlePublish con los datos apropiados
      // console.log(jsonString);
      
    }, [selectedTab]);

    
    useEffect(() => {
    //   if(waiting) {return};

    //   const jsonData = {
    //     path: "\graph",
    //     sensor:sensor,
    //     dataspeer:selectedTab
    //   };
    //   const jsonString = JSON.stringify(jsonData);
    //   handlePublish("REQUEST/" + currentDevice, jsonString); // Llamar a handlePublish con los datos apropiados
    //   // console.log(jsonString);
    //   setWaiting(true);

    // setSelectedTab(dataPeer);
    switch (dataPeer) {
      case 'minuts':
        setValue(0);
        break ;
      case 'hours':
        setValue(1);
        break;
      case '24h':
        setValue(2);
        break;
      case 3:
        // return <ChartSettings sensor={sensor} />;
      default:
        return null;
    }

    
    }, []);


  // Función para renderizar el componente correspondiente a la pestaña seleccionada
  const renderSelectedComponent = () => {
    switch (selectedTab) {
      case 'minuts':
        return <ReChart sensor={sensor} dataPeer={dataPeer} currentResponse={currentResponse}/>;
      case 'hours':
        return <ReChart sensor={sensor} dataPeer={dataPeer} currentResponse={currentResponse}/>;
      case '24h':
        return <ReChart sensor={sensor} dataPeer={dataPeer} currentResponse={currentResponse}/>;
      case 'settings':
        // return <ChartSettings sensor={sensor} />;
      default:
        return null;
    }
  };

  return (
    <>
      {/* Renderizar las pestañas */}
      <Box sx={{ bgcolor: 'background.paper', width: '100%',
        marginTop: "0%",
        height: "100%"
        }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"

          sx={{ bgcolor: '#b67886'

        }}
        >
          
          <Tab label="Last minuts" />
          <Tab label="Last hours"  />
          <Tab label="Last 24h" />
        </Tabs>
      </AppBar>
     
      <Grid container spacing={2} sx={{ padding: 2 }}>
        <Grid item xs={12} md={6}>
          <WaterJar valor={(currentResponse && currentResponse.readings)?currentResponse.readings[0]:null} units={(currentResponse && currentResponse.units)?currentResponse.units:""}/>
        </Grid>
        <Grid item xs={12} md={6}>
          {renderSelectedComponent()}
        </Grid>
      </Grid>  
   
   
    </Box>
      
      {/* Renderizar el componente correspondiente a la pestaña seleccionada */}
    </>
  );
};

export default TabChart;
