import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Security, Cloud, Wifi } from '@mui/icons-material';
import config from './config.json';

// Definimos los valores posibles para feature.icon
type IconType = 'Security' | 'Cloud' | 'Wifi';

const icons: Record<IconType, JSX.Element> = {
  Security: <Security fontSize="large" />,
  Cloud: <Cloud fontSize="large" />,
  Wifi: <Wifi fontSize="large" />
};

const Features: React.FC = () => {
  return (
    <Box sx={{ padding: 5, backgroundColor: '#1a1a1a' }}>
      <Typography variant="h4" gutterBottom align="center" color="primary" sx={{ fontFamily: 'Orbitron, sans-serif' }}>
        {config.features.title}
      </Typography>
      <Grid container spacing={4}>
        {config.features.items.map((feature, index) => (
          <Grid item xs={12} md={4} key={index} sx={{ textAlign: 'center' }}>
            {icons[feature.icon as IconType]}  {/* Aseguramos que el icono esté tipado correctamente */}
            <Typography variant="h6" gutterBottom color="primary" sx={{ fontFamily: 'Orbitron, sans-serif' }}>
              {feature.title}
            </Typography>
            <Typography sx={{ fontFamily: 'Roboto, sans-serif', color: '#ffffff' }}>
              {feature.description}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Features;
