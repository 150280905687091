import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import Slider from 'react-slick';
import config from './config.json';
import './Banner.css';

interface BannerProps {
  imageUrls?: string[];
}

const Banner: React.FC<BannerProps> = ({ imageUrls }) => {
  const finalImageUrls = imageUrls || config.banner.imageUrls;

  // Configuración del carrusel (Slider)
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <Box sx={{ width: '100%', position: 'relative', paddingTop:  {
      xs: '15%', // Tamaño más pequeño en pantallas pequeñas (mobile)
      md: '17%', // Tamaño intermedio en pantallas medianas
      lg: '5%', // Tamaño grande en pantallas grandes
    } }}>
      {/* Carrusel de imágenes */}
      <Slider {...settings}>
        {finalImageUrls.map((url, index) => (
          <Box
            key={index}
            sx={{
              height: '500px',
              backgroundImage: `url(${url})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#ffffff',
            }}
          >
            {/* Contenedor del texto */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.25)', // Fondo semitransparente
                padding: '20px',
                borderRadius: '8px',
              }}
            >
              {/* Título */}
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontFamily: 'Orbitron, sans-serif',
                  color: '#ffffff',
                  fontSize: {
                    xs: '2rem', // Tamaño más pequeño en pantallas pequeñas (mobile)
                    md: '3rem', // Tamaño intermedio en pantallas medianas
                    lg: '4rem', // Tamaño grande en pantallas grandes
                  },
                }}
              >
                {config.banner.title}
              </Typography>
              {/* Subtítulo */}
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  fontFamily: 'Roboto, sans-serif',
                  color: '#ffffff',
                  fontSize: {
                    xs: '1.2rem', // Tamaño más pequeño en pantallas pequeñas
                    md: '1.5rem', // Tamaño intermedio en pantallas medianas
                    lg: '2rem', // Tamaño grande en pantallas grandes
                  },
                }}
              >
                {config.banner.subtitle}
              </Typography>
              {/* Botón */}
              <Button
                variant="contained"
                color="primary"
                size="large"
                sx={{
                  fontSize: {
                    xs: '0.8rem', // Botón más pequeño en pantallas pequeñas
                    md: '1rem', // Tamaño intermedio en pantallas medianas
                  },
                  padding: {
                    xs: '8px 16px', // Aumentar el padding en pantallas pequeñas
                    md: '10px 20px', // Padding mayor en pantallas medianas
                  },
                }}
              >
                {config.banner.buttonText}
              </Button>
            </Box>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default Banner;
