import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Paper,
  Typography,
  Fab,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from 'notistack';

interface User {
  id: number;
  username: string;
  email: string;
  password?: string;
  broker: string;
  broker_user: string;
  broker_pass: string;
  role_id: number;
}

const UserManagement: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [form, setForm] = useState<User>({
    id: 0,
    username: '',
    email: '',
    password: '',
    broker: '',
    broker_user: '',
    broker_pass: '',
    role_id: 2
  });
  const [editingUserId, setEditingUserId] = useState<number | null>(null);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      if (!localStorage.getItem('token')) {
        navigate('/');
        return;
      }
      const response = await axios.get('https://iotayudo.com/wstomqtt/users.php', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setUsers(response.data);
    } catch (error) {
      enqueueSnackbar('Error fetching users', { variant: 'error' });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    try {
      if (editingUserId) {
        await axios.put(`https://iotayudo.com/wstomqtt/users.php?id=${editingUserId}`, form, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        enqueueSnackbar('User updated successfully', { variant: 'success' });
      } else {
        await axios.post('https://iotayudo.com/wstomqtt/users.php', form, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        enqueueSnackbar('User created successfully', { variant: 'success' });
      }
      setForm({ id: 0, username: '', email: '', password: '', broker: '', broker_user: '', broker_pass: '', role_id: 2 });
      setEditingUserId(null);
      setOpen(false);
      fetchUsers();
    } catch (error) {
      enqueueSnackbar('Error saving user', { variant: 'error' });
    }
  };

  const handleEdit = (user: User) => {
    setEditingUserId(user.id);
    setForm({ ...user, password: '' });
    setOpen(true);
  };

  const handleCancelEdit = () => {
    setForm({ id: 0, username: '', email: '', password: '', broker: '', broker_user: '', broker_pass: '', role_id: 2 });
    setEditingUserId(null);
    setOpen(false);
  };

  const handleDelete = async (id: number) => {
    try {
      await axios.delete(`https://iotayudo.com/wstomqtt/users.php?id=${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      enqueueSnackbar('User deleted successfully', { variant: 'success' });
      fetchUsers();
    } catch (error) {
      enqueueSnackbar('Error deleting user', { variant: 'error' });
    }
  };

  const handleSignOut = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" padding={4}>
      <Button variant="outlined" color="error" onClick={handleSignOut} sx={{ alignSelf: 'flex-end', mb: 2 }}>
        Sign Out
      </Button>

      <Fab 
          color="primary" 
          aria-label="add" 
          onClick={() => setOpen(true)} 
          sx={{ 
            position: 'fixed', 
            bottom: 16, 
            right: 16 
          }}
        >        <AddIcon />
      </Fab>




      {/* Modal para Crear / Editar Usuario */}
      <Dialog open={open} onClose={handleCancelEdit}>
        <DialogTitle>{editingUserId ? 'Edit User' : 'Create User'}</DialogTitle>
        <DialogContent>
          <TextField label="Username" name="username" value={form.username} onChange={handleInputChange} fullWidth margin="normal" />
          <TextField label="Email" name="email" value={form.email} onChange={handleInputChange} fullWidth margin="normal" />
          <TextField label="Password" name="password" type="password" value={form.password} onChange={handleInputChange} fullWidth margin="normal" />
          <TextField label="Broker" name="broker" value={form.broker} onChange={handleInputChange} fullWidth margin="normal" />
          <TextField label="Broker User" name="broker_user" value={form.broker_user} onChange={handleInputChange} fullWidth margin="normal" />
          <TextField label="Broker Password" name="broker_pass" type="password" value={form.broker_pass} onChange={handleInputChange} fullWidth margin="normal" />
          
          <div style={{ marginTop: '16px' }}>
            <label>Role</label>
            <select name="role_id" value={form.role_id} onChange={handleSelectChange} style={{ width: '100%', padding: '8px', marginTop: '8px' }}>
              <option value={1}>Admin</option>
              <option value={2}>User</option>
            </select>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelEdit} color="secondary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary">{editingUserId ? 'Update' : 'Create'}</Button>
        </DialogActions>
      </Dialog>

      <TableContainer component={Paper} sx={{ marginTop: 4, overflowX: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ whiteSpace: 'nowrap', width: 50 }}>ID</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>Username</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>Email</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap', width: 100 }}>Role</TableCell>
              
              <TableCell sx={{ whiteSpace: 'nowrap' }}>Broker</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>Broker User</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>Broker Pass</TableCell>
              
              <TableCell sx={{ whiteSpace: 'nowrap', width: 100 }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {user.id}
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 120 }}>
                  {user.username}
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 180 }}>
                  {user.email}
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 80 }}>
                  {user.role_id === 1 ? 'Admin' : 'User'}
                </TableCell>
                
                <TableCell sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 140 }}>
                  {user.broker}
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 140 }}>
                  {user.broker_user}
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 140 }}>
                  {user.broker_pass}
                </TableCell>
                
                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                  <Fab size="small" color="secondary" onClick={() => handleEdit(user)} sx={{ mr: 1 }}>
                    <EditIcon />
                  </Fab>
                  <Fab size="small" color="error" onClick={() => handleDelete(user.id)}>
                    <DeleteIcon />
                  </Fab>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    </Box>
  );
};

export default UserManagement;
