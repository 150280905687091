import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, Paper, TextField, Typography, createTheme, ThemeProvider } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate para redirigir
import imageUrl from './back1.png';
import GlitchImage from "./GlitchImage";

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [processing, setProcessing] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  // Verificar si el token es válido al montar el componente
  useEffect(() => {
    const checkToken = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.post('https://iotayudo.com/wstomqtt/validatetoken.php', {}, {
            headers: { Authorization: `Bearer ${token}` }
          });
          // navigate('/DeviceManagement');
        } catch (err) {
          console.log('Token inválido o expirado');
          enqueueSnackbar('Token inválido o expirado!', { variant: 'error' });
        }
      }
    };
    checkToken();
  }, [navigate, enqueueSnackbar]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setProcessing(true);

    try {
      const response = await axios.post('https://iotayudo.com/wstomqtt/login.php', {
        email,
        password
      });

      const { token, id } = response.data;
      localStorage.setItem('token', token);
      localStorage.setItem('id_user', id);
      enqueueSnackbar('Login exitoso!', { variant: 'success' });
      setProcessing(false);
      navigate('/DeviceManagement');
    } catch (error) {
      enqueueSnackbar('Credenciales incorrectas. Inténtalo de nuevo.', { variant: 'error' });
      setProcessing(false);
    }
  };

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#90caf9',
      },
      background: {
        default: '#121212',
        paper: '#1e1e1e',
      },
      text: {
        primary: '#ffffff',
      },
    },
    typography: {
      fontFamily: 'Roboto, sans-serif',
      h5: {
        fontWeight: 'bold',
        color: '#ffffff',
      },
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
            {/* <GlitchImage
          imageUrl={imageUrl}
          glitchInterval={100}
          glitchSize={0.025}  // 5% del tamaño del área como glitch
          width={800}
          height={500}
        /> */}
          <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        // sx={{
        //   backgroundImage: `url(${imageUrl})`,  // Imagen como fondo
        //   backgroundSize: 'cover',              // Tamaño de la imagen de fondo
        //   backgroundPosition: 'center',         // Centramos la imagen
        //   backgroundRepeat: 'no-repeat',        // Evitar que la imagen se repita
        //   position: 'relative',                 // Para permitir aplicar el glitch
        // }}
      >
         <div>

      </div>

        <Paper
          elevation={3}
          sx={{
            padding: 4,
            maxWidth: 400,
            width: '100%',
            textAlign: 'center',
            backgroundColor: 'background.paper',
            position: 'relative',
            zIndex: 1,  // Aseguramos que el Paper esté sobre el fondo
          }}
        >
          <Typography variant="h5" mb={3}>
            Login
          </Typography>

          <form onSubmit={handleSubmit}>
            <TextField
              label="Email"
              type="email"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              margin="normal"
              required
              InputLabelProps={{
                style: { color: '#90caf9' },
              }}
              InputProps={{
                style: { color: '#ffffff' },
              }}
            />
            <TextField
              label="Password"
              type="password"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              margin="normal"
              required
              InputLabelProps={{
                style: { color: '#90caf9' },
              }}
              InputProps={{
                style: { color: '#ffffff' },
              }}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
              disabled={processing}
            >
              {processing ? 'Processing...' : 'Login'}
            </Button>
          </form>
        </Paper>
      </Box>
    </ThemeProvider>
  );
};

export default Login;
