import React, { useState } from 'react';
import axios from 'axios';
import { Button, TextField, Typography, Box, CircularProgress } from '@mui/material';

const OpenAIRequest = () => {
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [prompt, setPrompt] = useState('Say this is a test!');

  const handleSubmit = async () => {
    setLoading(true);
    setError('');
    try {
      const result = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: [{ role: 'user', content: prompt }],
          temperature: 0.7
        },
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'Content-Type': 'application/json'
          }
        }
      );
      setResponse(result.data.choices[0].message.content);
    } catch (error) {
      setError('Error fetching data from OpenAI: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, p: 2 }}>
      <Typography variant="h4" gutterBottom>
        OpenAI API Request
      </Typography>
      <TextField
        label="Prompt"
        variant="outlined"
        fullWidth
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
      />
      <Button variant="contained" color="primary" onClick={handleSubmit} disabled={loading}>
        {loading ? <CircularProgress size={24} /> : 'Enviar'}
      </Button>
      {error && <Typography color="error">{error}</Typography>}
      {response && (
        <Box sx={{ mt: 2, p: 2, border: '1px solid #ccc', borderRadius: '4px', width: '100%' }}>
          <Typography variant="h6">Respuesta:</Typography>
          <Typography>{response}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default OpenAIRequest;
