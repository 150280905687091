import React from 'react';
import { Box, Container, Typography, Grid, Card, CardContent, List, ListItem, ListItemIcon, ListItemText, Fade, IconButton, ImageList, ImageListItem } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';



import Banner from './Banner';
import TitleSection from './TitleSection';
import InfoSection from './InfoSection';
import PackagesSection from './PackagesSection';
import BenefitsSection from './BenefitsSection';
import CentralizationPlatformSection from './CentralizationPlatformSection';
import DirectActionsSection from './DirectActionsSection';
import ApplicationAreas from './ApplicationAreas';
import Footer from './Footer';
import WhatsAppButton from './WhatsAppButton';

import 'animate.css';


const App: React.FC = () => {


    return (
        <Box sx={{ backgroundColor: '#0d0d25', minHeight: '100vh', color: 'white', padding: 2 }}>
            {/* Header */}

            <Banner/>            
           
            <TitleSection />
            <InfoSection />            
            <PackagesSection />            
            <BenefitsSection />           
            <CentralizationPlatformSection />            
            <DirectActionsSection />            
            <ApplicationAreas />            
            <Footer />            
            <WhatsAppButton />            
            
    </Box>
    );
};

export default App;
