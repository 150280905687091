import React, { lazy, FC, useEffect, useState } from "react";
import { Switch,Avatar, Button, IconButton, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Theme, useTheme } from "@mui/material";


import RefreshIcon from '@mui/icons-material/Refresh';
// import defaultData from './defaultData.json';
import Peripheric  from './Peripheric';
import ForceDirectedTreeComponent from './ForceDirectedTreeComponent';
// import SmartHomeTemperatureSlider from './SmartHomeTemperatureSlider';
// import Thermostat from "./Thermostat";

const PeriphericsStatus = {
  NAMEDEVICE: '',
  Perifs: [] // Esto se rellenará con objetos de tipo Perif
};

const Perif = {
  EnGpio: false,
  GpIOTyp: 0,
  IDName: '',
  state: 0,
  IDper: '',
  PinOut: 0,
  io: '',
  idx: 0
};

const PeriphericsStatusForm = ({currentResponse,handlePublish,currentDevice,controlState, selectedItem}) => {
  // const [controlState, setControlState] = useState(false);

  const handleSwitchChange = () => {
    const newControlState = !controlState; // Invertir el estado actual
    // setControlState(newControlState); // Actualizar el estado del Switch
    const jsonData = {
      setControlState: newControlState
    };
    const jsonString = JSON.stringify(jsonData);
    handlePublish("REQUEST/" + currentDevice, jsonString); // Llamar a handlePublish con los datos apropiados
  };

  // useLayoutTitle("Peripherics");
  // const [data, setData] = useState(defaultData.PeripheralService);
  const [data, setData] = useState(null);  
  // const [errorMessage, setErrorMessage] = useState<null>(null);  
  // const handleRefresh = () => {
  //   setData(defaultData.PeripheralService);
  //   // setErrorMessage(null);
  // };  
  
  useEffect(() => {
    // alert(currentResponse);
    try {
      // const jsonData = JSON.parse(currentResponse);
      setData(currentResponse);      
     console.log('setData to:'+currentResponse);

    } catch (error) {
      console.error('Error al parsear JSON:', error);
      setData(null); // Establecer data como null si hay un error al parsear JSON
    }
    // setData(currentResponse); // Actualizar data cuando currentResponse cambie
  }, [currentResponse]);


  useEffect(() => {
  }, [selectedItem]);


  const content = () => {
    // console.log(data)
    if (!data||!data.Perifs) {
      return ( <></>
        );
    }else{
      //alert(data);
      // console.log(data.Perifs);
    }
  return (
    <>    
        <ForceDirectedTreeComponent HDM={data} />
        {/* <ForceDirectedTree /> */}

        {/* <SmartHomeTemperatureSlider temperature={50} /> */}

        {/* <Thermostat initialValue={42} /> */}


      <Table size="small">
        <TableHead>
          {/* {data.NAMEDEVICE} */}
          <TableRow sx={{ textAlign:"center" }}>
            <TableCell align="center">I/O</TableCell>
            <TableCell align="center">Action/Value</TableCell>

            {/*<TableCell align="center">Type</TableCell>
          <TableCell align="center">ID</TableCell>*/}
            <TableCell align="center">NAME</TableCell>
            {/*<TableCell align="center">GPIO</TableCell>
            <TableCell align="center">indx</TableCell>*/}
            {/* <TableCell /> */}
          </TableRow>
        </TableHead>
        <TableBody>

        {data.Perifs.map((comp, idx) => (              
              <Peripheric perif={comp} idx={idx}  
                          handlePublish={handlePublish} 
                          currentDevice={currentDevice} />
            ))}


        </TableBody>
        <TableFooter >
          <TableRow>
            <TableCell colSpan={3} />
            <TableCell align="center" padding="normal">
              {/*<Button startIcon={<PersonAddIcon />} variant="contained" color="secondary" >
                Add
              </Button>*/}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
      {/* <ButtonRow pt={1}> */}
       
        CONTROL STATE: 
        <Switch
                      name="led_on"
                      color="primary"
                      checked={controlState} // Utilizar el estado del Switch
                      onChange={handleSwitchChange} // Manejar el cambio del Switch
                    /> 


      {/* <Button startIcon={<RefreshIcon />} variant="contained" color="secondary" onClick={handleRefresh}>
          Refresh
        </Button> */}
      {/* </ButtonRow> */}
    </>
  );
 }

 return (
  <>
    {content()}
  </>
);

};

export default PeriphericsStatusForm;
