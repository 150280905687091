import React from 'react';
import { Box, Divider, Drawer, Toolbar, Typography, styled } from '@mui/material';
import { PROJECT_NAME } from './api/env';
import LayoutMenu from './layoutMenu';
import { DRAWER_WIDTH } from './Layout';

const LayoutDrawerLogo = styled('img')(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    height: 24,
    marginRight: theme.spacing(2)
  },
  [theme.breakpoints.up("sm")]: {
    height: 36,
    marginRight: theme.spacing(2)
  }
}));



const LayoutDrawer = ({ mobileOpen, 
                                    onClose,
                                    ctdsensor, 
                                    setSelectedItem,
                                    selectedItem,

                                    setCurrentResponse,
                                    setCurrentDevice,
                                    imageSrc,fade,qrcode
                                  }) => {
                                    
    const selectOptions = [
        // { label: 'Seleccione un device', value: '' }, // Opción por defecto

        
        { label: 'DEVICE', value:qrcode },
        // { label: 'LOMBRICOMPOSTA', value: 'esp32-4022d8ea4720' },
        // { label: 'TINACO_AZOTEA', value: 'esp32-94e6863c6134' },
        // { label: 'ALJIBE_ENTRADA', value: 'esp32-08d1f9e7f0d8' }        
      ];
      
    const drawer = (
    <>
      <Toolbar disableGutters>
        <Box display="flex" alignItems="center" px={2}>
          <LayoutDrawerLogo src="/favicon.png" alt={PROJECT_NAME} />
          <Typography variant="h6" color="textPrimary">
            {"iOTAyudo.com"}
          </Typography>
        </Box>
       

        <Divider absolute />
      </Toolbar> {imageSrc && <><img 
                className={`fade ${fade ? 'show' : ''}`}
                src={imageSrc} 
                alt="Imagen" /></>}
      <Divider />
      <LayoutMenu ctdsensor={ctdsensor}
                setSelectedItem={setSelectedItem} 
                selectedItem={selectedItem}
                onClose={onClose}

                selectOptions={selectOptions} 
                setCurrentResponse={setCurrentResponse} 
                setCurrentDevice={setCurrentDevice}
                qrcode={qrcode}/>
    </>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { md: DRAWER_WIDTH }, flexShrink: { md: 0 } }}
    >
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={onClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
          disableScrollLock: true, // Evita que se bloquee el scroll.
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', md: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default LayoutDrawer;
