import React, { FC, useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Button } from "@mui/material";
import { enqueueSnackbar } from 'notistack';
import RefreshIcon from '@mui/icons-material/Refresh';
import { SectionContent, FormLoader, ButtonRow } from './components';  // Si tienes un index.ts o index.js
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useTheme } from "@mui/material/styles";

import OutputIcon from '@mui/icons-material/Output';
import InputIcon from '@mui/icons-material/Input';

import Peripheric  from './Peripheric2';
import { PeriphericsStatus } from './types';


const useRest = (loadFunction: any) => {
//   const [data, setData] = useState(null);
  const [data, setData] = useState<PeriphericsStatus | null>(null);


const [errorMessage, setErrorMessage] = useState("");

  const loadData = async () => {
    try {
      const response = await loadFunction();

      const { message, response: serviceResponse } = response.data;

        if(message==="Response available"){
            if (serviceResponse.includes("NAMEDEVICE")) {
                // enqueueSnackbar(serviceResponse, { variant: 'success' });
                setData(JSON.parse(serviceResponse));
            }else{
                enqueueSnackbar(serviceResponse, { variant: 'info' });

             }

            
                
        }else{
            // enqueueSnackbar(message, { variant: 'success' });
            // setData(message);
      
        }
      setErrorMessage("null");
    } catch (error) {
      setErrorMessage('Error loading data');
    }
  };

  return { loadData, data, errorMessage };
};

const registerService = async (qrcode: string) => {
  return axios.post(`http://localhost/reactlogin/requestservicemqtt.php`,
    {  qr_code: qrcode,pulling:true },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
};

const DeviceMonitor: FC = () => {
  const { qrcode } = useParams();
  const { loadData, data, errorMessage } = useRest(() => {
    if (qrcode) {
      return registerService(qrcode); 
    } else {
      return Promise.reject('QR code is missing');
    }
  });

  const [topic, setTopic] = useState('REQUEST/'+qrcode);
  const [message, setMessage] = useState('showmeyougot');
  const theme = useTheme();

  useEffect(() => {
    const timer = setInterval(() => {
        loadData();

    }, 3000);
    return () => clearTimeout(timer);
  }, [loadData]);
  
  useEffect(() => {
    // loadData();
    checkRegistered();
  }, []);


  const checkRegistered= async () => {
    try {
        const response = await axios.post(
          `http://localhost/reactlogin/requestservicemqtt.php`,
          { qr_code: qrcode },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        enqueueSnackbar('Message sent successfully', { variant: 'success' });
        // setTopic('');
        // setMessage('');
      } catch (error) {
        enqueueSnackbar('Failed to send message', { variant: 'error' });
      }
    
 }

  const handleSend = async () => {
    if (topic && message) {
      try {
        const response = await axios.post(
          `http://localhost/reactlogin/requestservicemqtt.php`,
          { topic:topic, message:message, qr_code: qrcode },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        enqueueSnackbar('Message sent successfully', { variant: 'success' });
        // setTopic('');
        // setMessage('');
      } catch (error) {
        enqueueSnackbar('Failed to send message', { variant: 'error' });
      }
    } else {
      enqueueSnackbar('Please fill both topic and message fields', { variant: 'warning' });
    }
  };


  const content = () => {
    if (!data) {
      return (<FormLoader onRetry={loadData} errorMessage={errorMessage || undefined} />);
    }

    return (
      <>
       <Table size="small">
          <TableHead>{data.NAMEDEVICE}

            <TableRow>
              <TableCell>I/O</TableCell>
              <TableCell align="center">Action/Value</TableCell>
              
            {/*<TableCell align="center">Type</TableCell>
            <TableCell align="center">ID</TableCell>*/} 
              <TableCell align="center">NAME</TableCell>
            {/*<TableCell align="center">GPIO</TableCell>
              <TableCell align="center">indx</TableCell>*/} 
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
           
            {/* {data.Perifs.map((comp, idx) => (              
              <Peripheric perif={comp} />
            ))} */}

            {data && data.Perifs.map((comp: any, idx: number) => (
            <Peripheric key={idx} perif={comp} qrcode={qrcode|| ''}/>
            ))}
          </TableBody>
          <TableFooter >
            <TableRow>
              <TableCell colSpan={3} />
              <TableCell align="center" padding="normal">
                {/*<Button startIcon={<PersonAddIcon />} variant="contained" color="secondary" >
                  Add
                </Button>*/}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
        
      </>
    );
  };

  return (
    <SectionContent title={`Monitor for device: ${qrcode}`} titleGutter>

<Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left">Topic</TableCell>
              <TableCell align="center">Action/Value</TableCell>
              <TableCell align="center">Message</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{topic}</TableCell>
              <TableCell align="center">
                <input
                  type="text"
                  value={topic}
                  onChange={(e) => setTopic(e.target.value)}
                  placeholder="Enter topic"
                />
              </TableCell>
              <TableCell align="center">
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Enter message"
                />
              </TableCell>
              <TableCell align="center">
                <Button variant="contained" color="primary" onClick={handleSend}>
                  Send
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={3} />
              <TableCell align="center" padding="normal">
                
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
        


      {content()}
    </SectionContent>
  );
};

export default DeviceMonitor;
