import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import config from './config.json';

const Devices: React.FC = () => {
  return (
    <Box sx={{ padding: 5, backgroundColor: '#2a2a2a' }}>
      <Typography
        variant="h4"
        gutterBottom
        align="center"
        color="primary"
        sx={{ fontFamily: 'Orbitron, sans-serif' }}
      >
        {config.devices.title}
      </Typography>
      <Grid container spacing={4}>
        {config.devices.items.map((device, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Paper
              sx={{
                padding: 3,
                backgroundColor: '#1e1e1e',
                color: '#ffffff',
                textAlign: 'center'
              }}
            >
              {/* Imagen del dispositivo */}
              <Box
                component="img"
                src={device.image}
                alt={device.name}
                sx={{
                  width: '100%',
                  height: '200px',
                  objectFit: 'cover',
                  borderRadius: '8px',
                  marginBottom: 2
                }}
              />
              {/* Nombre del dispositivo */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontFamily: 'Orbitron, sans-serif' }}
              >
                {device.name}
              </Typography>
              {/* Descripción del dispositivo */}
              <Typography sx={{ fontFamily: 'Roboto, sans-serif' }}>
                {device.description}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Devices;
