import React, { FC, useState } from "react";
import { Box, Button, Divider, IconButton, Popover, Typography, Avatar, styled } from '@mui/material';
import PersonIcon from "@mui/icons-material/Person";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from 'react-router-dom';

const ItemTypography = styled(Typography)(({ theme }) => ({
  maxWidth: '250px',
  whiteSpace: 'nowrap',
  // overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const LayoutAuthMenu=() => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSignOut = () => {
    localStorage.removeItem('token');
    navigate('/');
    // alert();
  };
  const open = Boolean(anchorEl);
  const id = anchorEl ? 'app-menu-popover' : undefined;
  const navigate = useNavigate();
  
  
  const goDevices = () => {
    // localStorage.removeItem('token');
    navigate('/DeviceManagement');
  };
  return (
    <>
          <Button variant="outlined" color="error" onClick={goDevices} sx={{ alignSelf: 'flex-end', mb: 2 }}>
        Devices
      </Button>
      <Box flexGrow={1} />

      <IconButton
        id="open-auth-menu"
        sx={{ padding: 0 }}
        aria-describedby={id}
        color="inherit"
        onClick={handleClick}
      >
        <AccountCircleIcon />
      </IconButton>
      <Popover
        id="app-menu-popover"
        sx={{ mt: 1 }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box display="flex" flexDirection="row" alignItems="center" p={2}>
          <Avatar sx={{ width: 80, height: 80 }}>
            <PersonIcon fontSize="large" />
          </Avatar>
          <Box pl={2}>
            <ItemTypography variant="h6">
              {"me.username"}
            </ItemTypography>
            <ItemTypography variant="body1">
              {"Admin User" }
            </ItemTypography>
          </Box>
        </Box>
        <Divider />
        <Box p={1.5}>
          <Button variant="contained" fullWidth color="primary" onClick={handleSignOut}>Sign Out</Button>
        </Box>
      </Popover>
    </>
  );
};

export default LayoutAuthMenu;
