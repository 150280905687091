import React, { useEffect, useState } from 'react';
import './WaterJar.css';

const WaterJar = ({ valor,units }) => {
  const [percent, setPercent] = useState(valor || 0);
  const [unit, setUnit ]= useState(units || " ");
  const [bcolor, setBcolor]= useState("");
  const [cwave, setCwave]= useState("cTemback");
  
  useEffect(() => {
    setPercent(valor);

    if (units && units.includes("°C")) {
      setUnit("°C");
      setBcolor("linear-gradient(rgb(124, 251, 8), rgb(0, 8, 255))");
      setCwave("cTemback");
    
    }else if (units && units.includes("umed")) {
      setUnit(" %H");
      setBcolor("linear-gradient(rgb(77 153 227), rgb(84 255 0))");
      setCwave("cHumeback");

    }else if (units && units.includes("%")) {
      setUnit(" %");
      setBcolor("linear-gradient(rgb(77, 109, 227), rgb(199, 238, 255))");
      setCwave("cLevback");
    }else{
      setUnit(" ");
      setBcolor("");
      setCwave("")
    }
  }, [valor, units,bcolor,cwave]);



  const renderSvg = () => {
    return (
      <>
        <svg viewBox="0 0 560 20" className={`water_wave water_wave_back ${cwave}`}>
          <use xlinkHref="#wave"></use>
        </svg>
        <svg viewBox="0 0 560 20" className={`water_wave water_wave_front ${cwave}`}>
          <use xlinkHref="#wave"></use>
        </svg>
      </>
    );
  };
  useEffect(() => {
    const waterElement = document.getElementById('water');
    if (waterElement) {
      const clone = waterElement.cloneNode(true);
      waterElement.parentNode.replaceChild(clone, waterElement);
      clone.style.transform = `translate(0, ${100 - percent}%)`;
      clone.style.background = bcolor;//'linear-gradient(to bottom, #4D6DE3, #C7EEFF)';

      // Encuentra los elementos SVG dentro del clon y actualiza su className
      const svgElements = clone.getElementsByTagName('svg');
      for (let svg of svgElements) {
        if (svg.classList.contains('water_wave_back')) {
          // svg.className = `water_wave water_wave_back ${cwave}`;
          svg.setAttribute('class', `water_wave water_wave_back ${cwave}`);
        } else if (svg.classList.contains('water_wave_front')) {
          // svg.className = `water_wave water_wave_front ${cwave}`;
          svg.setAttribute('class', `water_wave water_wave_front ${cwave}`);
        }
      }

    }
    // alert(percent);
  }, [percent,bcolor,cwave]);
  return (
    <>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="https://www.w3.org/1999/xlink" style={{ display: 'none' }}>
        <symbol id="wave">
          <path d="M420,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C514,6.5,518,4.7,528.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H420z"></path>
          <path d="M420,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C326,6.5,322,4.7,311.5,2.7C304.3,1.4,293.6-0.1,280,0c0,0,0,0,0,0v20H420z"></path>
          <path d="M140,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C234,6.5,238,4.7,248.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H140z"></path>
          <path d="M140,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C46,6.5,42,4.7,31.5,2.7C24.3,1.4,13.6-0.1,0,0c0,0,0,0,0,0l0,20H140z"></path>
        </symbol>
      </svg>
      <div className="water-jar">
        <div className="water-filling">
          <div className="percentNum" id="count">{percent}</div>
          <div className="percentB">{unit}</div>
        </div>

        {/* <div id="water" className="water" style={{ transform: `translate(0, ${100 - percent}%)`}}> */}
        <div id="water" className="water" style={{ transform: `translate(0, ${100 - percent}%)`, background: {bcolor} }}>

          {renderSvg()}
        </div>
        
        <div className="unitsB">{units}</div>
      </div>
    </>
  );
};

export default WaterJar;
