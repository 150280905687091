import React, { useRef, useEffect, useState } from 'react';
import { useMediaQuery, Theme , Box, Card, CardContent, Container, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Fade } from '@mui/material';
import { useInView } from 'react-intersection-observer';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import 'animate.css'; // Importación de animate.css para animaciones

const PackagesSection: React.FC = () => {
  // const [animated, setAnimated] = useState(false);  
  // const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  //   const listenToScroll = () => {
  //     if (!isDesktop) {
  //       // alert("hi")
  //       return; // Solo aplica el efecto si es escritorio
  //     }
  //     const winScroll =
  //       document.body.scrollTop || document.documentElement.scrollTop;  
  //     const height =
  //       document.documentElement.scrollHeight - document.documentElement.clientHeight;  
  //     const scrolled = winScroll / height;
  //     if (scrolled > 0.102086 && scrolled < 0.3246) {
  //         setAnimated(true);
  //     } else { 
  //       setAnimated(false);
  //     }
  //   };  
  //   useEffect(() => {
  //     window.addEventListener('scroll', listenToScroll);  
  //     return () => {
  //       window.removeEventListener('scroll', listenToScroll);
  //     };
  //   }, []);
  const { ref, inView } = useInView({
    triggerOnce: false, // Solo activa la animación una vez
    threshold: 0.2, // 20% del elemento visible para activar
});
// Detecta si la pantalla es mayor que el tamaño "sm" (small) de Material-UI
const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));


  return (
    <Container
      sx={{
        paddingY: 5,
        maxWidth: {
          xs: '100%',    // Extra pequeñas
          sm: '100%',    // Pequeñas
          md: '100%',    // Medianas
          lg: '100%',    // Grandes
          xl: '2000px',  // Extra grandes
        },
      }}
      ref={ref}
    >
      <Typography variant="h3" align="center" gutterBottom>
        Paquetes:
      </Typography>

        
      <Grid container spacing={4} 
        // ref={ref}
        // className={`${animated}` }   
        // sx={{ flexDirection: { xs: 'column', lg: 'row' } }}

          >
        
        
        <Fade in={inView} timeout={1000} style={{ transitionDelay: inView ? '0ms' : '0ms' }}>

        <Grid item xs={12} sm={4} 
          // className={`animate__animated ${animated ? 'animate__fadeIn' : ''}`}  // Agrega clase de animación al ser visible
          >

          <Card sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', height: '100%', alignContent: "center" }}>
            <CardContent sx={{ height: '95%' }}>
              <Typography variant="h4" gutterBottom textAlign="center">Paquete 1</Typography>
              
              <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', lg: 'row' }, height: "75%" }}>
                <Box sx={{ flex: 1, marginTop: '3px' }}>
                  <List>
                    {["1 Sensor Tinaco", "Tarjeta Controladora", "Instalación"].map((item, index) => (
                      <ListItem key={index} sx={{ paddingY: 0.5 }}>
                        <ListItemIcon>
                          <CheckCircleIcon sx={{ color: 'primary.main' }} />
                        </ListItemIcon>
                        <ListItemText primary={item} />
                      </ListItem>
                    ))}
                  </List>
                </Box>
                
                <Box
                  sx={{
                    width: 200,
                    height: 200,
                    backgroundImage: 'url(/images/kit6.png)',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: 1,
                    marginLeft: 2,
                  }}
                />
              </Box>
              
              <Typography variant="h4" sx={{ fontWeight: 'bold', marginTop: 2, textAlign: 'center', fontSize: '3em' }}>
                $800
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        </Fade>
        
        {/* Paquete 2 */}
        <Fade in={inView} timeout={1000} style={{ transitionDelay: inView ? (isDesktop ? '300ms' : '0ms') : '0ms' }}>

        <Grid item xs={12} sm={4}
          // className={`animate__animated ${animated ? 'animate__fadeInUp' : ''}`}  // Agrega clase de animación al ser visible
          >
        
          <Card sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', height: '100%', alignContent: "center" }}>
            <CardContent sx={{ height: '95%' }}>
              <Typography variant="h4" gutterBottom textAlign="center">Paquete 2</Typography>
              
              <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', lg: 'row' }, height: "75%" }}>
                <Box sx={{ flex: 1, marginTop: '3px' }}>
                  <List>
                    {["1 Sensor Aljibe", "Tarjeta Controladora", "Relé para bomba de agua", "Instalación"].map((item, index) => (
                      <ListItem key={index} sx={{ paddingY: 0.5 }}>
                        <ListItemIcon>
                          <CheckCircleIcon sx={{ color: 'primary.main' }} />
                        </ListItemIcon>
                        <ListItemText primary={item} />
                      </ListItem>
                    ))}
                  </List>
                </Box>
                
                <Box
                  sx={{
                    width: 200,
                    height: 200,
                    backgroundImage: 'url(/images/kit11.png)',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: 1,
                    marginLeft: 2,
                  }}
                />
              </Box>
              
              <Typography variant="h4" sx={{ fontWeight: 'bold', marginTop: 2, textAlign: 'center', fontSize: '3em' }}>
                $1500
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        </Fade>

        {/* Paquete 3 */}
        <Fade in={inView} timeout={1000} style={{ transitionDelay: inView ? (isDesktop ? '600ms' : '0ms') : '0ms' }}>

        <Grid item xs={12} sm={4}
        // className={`animate__animated ${animated ? 'animate__fadeInDown' : ''}`}  // Agrega clase de animación al ser visible
        >
          <Card sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', height: '100%', alignContent: "center" }}>
            <CardContent sx={{ height: '95%' }}>
              <Typography variant="h4" gutterBottom textAlign="center">Paquete 3</Typography>
              
              <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', lg: 'row' }, height: "75%" }}>
                <Box sx={{ flex: 1, marginTop: '3px' }}>
                  <List>
                    {["1 Sensor Tinaco", "1 Sensor Aljibe", "Tarjeta Controladora", "Relé para bomba de agua", "Instalación"].map((item, index) => (
                      <ListItem key={index} sx={{ paddingY: 0.5 }}>
                        <ListItemIcon>
                          <CheckCircleIcon sx={{ color: 'primary.main' }} />
                        </ListItemIcon>
                        <ListItemText primary={item} />
                      </ListItem>
                    ))}
                  </List>
                </Box>
                
                <Box
                  sx={{
                    width: 200,
                    height: 200,
                    backgroundImage: 'url(/images/kit3.png)',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: 1,
                    marginLeft: 2,
                  }}
                />
              </Box>
              
              <Typography variant="h4" sx={{ fontWeight: 'bold', marginTop: 2, textAlign: 'center', fontSize: '3em' }}>
                $2000
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        </Fade>


      </Grid>
      

    </Container>
  );
};

export default PackagesSection;
