import React, { useEffect, useRef } from 'react';
import './Timeline.css';
// import './style.css';
import { Box, Typography, Button } from '@mui/material';

const Timeline = () => {
  const blocksRef = useRef([]);

  useEffect(() => {
    const options = {
      threshold: 0.1, // Dispara cuando el 10% del bloque esté visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, options);

    blocksRef.current.forEach((block) => {
      if (block) observer.observe(block);
    });

    return () => {
      blocksRef.current.forEach((block) => {
        if (block) observer.unobserve(block);
      });
    };
  }, []);

  const events = [
    {
      imgSrc: 'images/avatar.png',
      alt: 'Picture',
      title: 'Title of section 1',
      description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit...',
      date: 'Jan 14',
    },
    {
      imgSrc: 'images/avatar4.png',
      alt: 'Movie',
      title: 'Final Section',
      description: 'This is the content of the last section',
      date: 'Feb 26',
    },
    
    {
      imgSrc: 'images/avatar1.png',
      alt: 'Picture',
      title: 'Title of section 3',
      description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit...',
      date: 'Jan 24',
    },
    {
      imgSrc: 'images/avatar2.png',
      alt: 'Location',
      title: 'Title of section 4',
      description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit...',
      date: 'Feb 14',
    },
    {
      imgSrc: 'images/avatar3.png',
      alt: 'Location',
      title: 'Title of section 5',
      description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit...',
      date: 'Feb 18',
    },
  ];

  return (
    <Box sx={{ padding: 5, backgroundColor: '#2a2a2a' }}>

    <section id="cd-timeline" className="cd-container">
      {events.map((event, index) => (
        <div
          className="cd-timeline-block"
          key={index}
          ref={(el) => (blocksRef.current[index] = el)}
        >
          <div className="cd-timeline-img cd-picture"   
                style={{
                    backgroundImage: `url(${event.imgSrc})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}          
          >
            {/* <img src={event.imgSrc} alt={event.alt} /> */}
          </div>
          <div className="cd-timeline-content">
            <h2>{event.title}</h2>
            <p>{event.description}</p>
            <a href="#0" className="cd-read-more">Read more</a>
            <span className="cd-date">{event.date}</span>
          </div>
        </div>
      ))}
    </section>
    </Box>
  );
};

export default Timeline;
