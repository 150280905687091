import React, { useState,useEffect } from 'react';
import { Divider, List, MenuItem, Select, Typography, IconButton, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button ,Box} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Memory from '@mui/icons-material/Memory';
import TuneIcon from '@mui/icons-material/Tune';
import QrCodeIcon from '@mui/icons-material/QrCode';
import TimelineIcon from '@mui/icons-material/Timeline';
import CloudQueueSharpIcon from '@mui/icons-material/CloudQueueSharp';
import SettingsIcon from '@mui/icons-material/Settings';
import LockIcon from '@mui/icons-material/Lock';
import WifiIcon from '@mui/icons-material/Wifi';

import LayoutMenuItem from './LayoutMenuItem';

const LayoutMenu = ({ ctdsensor, setSelectedItem, selectedItem,onClose, selectOptions, setCurrentResponse, setCurrentDevice,qrcode }) => {
  const [selectedOption, setSelectedOption] = useState('null'); // Valor inicial seleccionado por defecto
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [newLabel, setNewLabel] = useState('');
  const [options, setOptions] = useState(selectOptions);

  const handleSystemClick = (path) => {
    setSelectedItem(path); // Actualiza el estado del elemento seleccionado
    if (onClose) onClose(); // Llama a onClose para cerrar el drawer en modo responsivo

  };

  useEffect(() => {
    setSelectedOption(qrcode)
    setSelectedOption(qrcode); // Actualizar estado con la opción seleccionada    
    setCurrentResponse(null);
    setCurrentDevice(qrcode);
    console.log('Opción seleccionada:', qrcode);
    // if (onClose) onClose(); // Llama a onClose para cerrar el drawer en modo responsivo

  }, []);


  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue); // Actualizar estado con la opción seleccionada    
    setCurrentResponse(null);
    setCurrentDevice(selectedValue);
    console.log('Opción seleccionada:', selectedValue);
    if (onClose) onClose(); // Llama a onClose para cerrar el drawer en modo responsivo

  };

  const handleEditClick = () => {
    const selectedOptionLabel = options.find(option => option.value === selectedOption)?.label || '';
    setNewLabel(selectedOptionLabel);
    setEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
  };

  const handleLabelChange = (event) => {
    setNewLabel(event.target.value);
  };

  const handleLabelSave = () => {
    const updatedOptions = options.map(option => 
      option.value === selectedOption ? { ...option, label: newLabel } : option
    );
    setOptions(updatedOptions);
    setEditDialogOpen(false);
  };

  const truncateValue = (value) => {
    if (value.length > 8) {
      return `...${value.slice(-8)}`;
    }
    return value;
  };
  const truncateLabel = (label) => {
    const maxLength = 12; // Número máximo de caracteres antes de truncar
    if (label.length > maxLength) {
      return `${label.substring(0, maxLength - 3)}...`;
    }
    return label;
  };
  return (
    <>
      <List disablePadding component="nav">
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Select
            value={selectedOption}
            onChange={handleSelectChange}
            sx={{ flexGrow: 1 }}
          >
            {/* <MenuItem value="null" disabled>
              Seleccione un device
            </MenuItem> */}
            {options.map(option => (
            <MenuItem 
                key={option.value} 
                value={option.value} 
                sx={{ 
                  overflow: 'hidden', 
                  textOverflow: 'ellipsis', 
                  whiteSpace: 'nowrap',
                  '&:hover': {
                    overflow: 'visible',
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                  }
                }}
                title={option.label}
              >
                <Typography component="span">
                  {truncateLabel(option.label)} [
                </Typography>
                <Typography component="span" sx={{ fontSize: '0.75em', marginLeft: '0.5em' }}>
                  {truncateValue(option.value)}
                </Typography>
                <Typography component="span">
                  ]
                </Typography>
              </MenuItem>
            ))}
          </Select>
          <IconButton onClick={handleEditClick} disabled={(!selectedOption || selectedOption === "null")?true:false}>
            <EditIcon />
          </IconButton>
        </Box>
        <Divider />
        <LayoutMenuItem icon={WifiIcon} label="WiFi Connection" to="/wifi" handleClick={() => handleSystemClick("/wifi")} disabled />
        {/* <LayoutMenuItem icon={SettingsInputAntennaIcon} label="Access Point" to="/ap" handleClick={() => handleSystemClick("/ap")} disabled /> */}
        <Divider />
        <LayoutMenuItem icon={Memory} label="Peripherals" handleClick={() => handleSystemClick("/perif")} selected={selectedItem === "/perif"} />
        <Divider />
        <LayoutMenuItem icon={TuneIcon} label="Control" handleClick={() => handleSystemClick("/control")} selected={selectedItem === "/control"} />
        <Divider />
        {ctdsensor && (
          <>
            {[...Array(ctdsensor)].map((_, index) => (
              <LayoutMenuItem
                key={index}
                icon={TimelineIcon}
                label={`Sensor ${index}`}
                handleClick={() => handleSystemClick(`/graph${index}`)}
                selected={selectedItem === `/graph${index}`}
              />
            ))}
          </>
        )}
        <Divider />
        <LayoutMenuItem icon={QrCodeIcon} label="QR code" handleClick={() => handleSystemClick("/ap")} disabled />
        <Divider />
        <LayoutMenuItem icon={CloudQueueSharpIcon} label="MQTT" to="/mqtt" disabled />
        <Divider />
        <LayoutMenuItem icon={AccessTimeIcon} label="Network Time" to="/ntp" disabled />
        <Divider />
        <LayoutMenuItem icon={LockIcon} label="Security" to="/security" disabled />
        <LayoutMenuItem icon={SettingsIcon} label="System" to="/system" disabled />
      </List>

      <Dialog open={editDialogOpen} onClose={handleEditDialogClose}>
        <DialogTitle>Edit Device Label</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Device Label"
            type="text"
            fullWidth
            value={newLabel}
            onChange={handleLabelChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLabelSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LayoutMenu;
