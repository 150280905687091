import React, { useState, useEffect } from 'react';
import mqtt from 'mqtt';

const MqttClient = () => {
  const [client, setClient] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [topic, setTopic] = useState('RESPONSE/esp32-08d1f9e7f0d8');
  const [message, setMessage] = useState('');
  const [receivedMessages, setReceivedMessages] = useState([]);
  
  // Obtener el token JWT desde el localStorage
  const jwt = localStorage.getItem('token');

  // Configuración de la conexión MQTT
  useEffect(() => {
    const options = {
      clientId: `cliente-${Math.random().toString(16).substr(2, 8)}`, // Identificador único
      username: 'mtx5833', // Vacío si solo usas JWT
      password: jwt, // El JWT como contraseña
      clean: true

    };
    alert(jwt);
    const mqttClient = mqtt.connect('navycarder-o51412.a02.usw2.aws.hivemq.cloud:8883', options);

    mqttClient.on('connect', () => {
        alert('Conectado a HiveMQ');
        console.log('Conectado a HiveMQ');
      setIsConnected(true);
      setClient(mqttClient);
    });

    mqttClient.on('message', (topic, message) => {
      console.log(`Mensaje recibido en el tópico ${topic}: ${message.toString()}`);
      setReceivedMessages(prevMessages => [...prevMessages, message.toString()]);
    });

    mqttClient.on('error', (err) => {
      console.error('Error de conexión:', err);
    });

    return () => {
      mqttClient.end(); // Desconectar al desmontar el componente
    };
  }, [jwt]);

  // Función para suscribirse a un tópico
  const handleSubscribe = () => {
    if (client && isConnected) {
      client.subscribe(topic, (err) => {
        if (err) {
          console.error('Error al suscribirse:', err);
        } else {
          console.log(`Suscrito al tópico ${topic}`);
        }
      });
    }
  };

  // Función para publicar un mensaje en un tópico
  const handlePublish = () => {
    if (client && isConnected) {
      client.publish(topic, message, (err) => {
        if (err) {
          console.error('Error al publicar:', err);
        } else {
          console.log(`Mensaje publicado en el tópico ${topic}: ${message}`);
        }
      });
    }
  };

  return (
    <div>
      <h1>Cliente MQTT - HiveMQ</h1>

      {isConnected ? <p>Conectado a HiveMQ</p> : <p>No conectado</p>}
      
      {/* Input para el tópico */}
      <div>
        <label htmlFor="topic">Tópico:</label>
        <input 
          type="text" 
          id="topic" 
          value={topic} 
          onChange={(e) => setTopic(e.target.value)} 
          placeholder="Escribe el tópico al que suscribirse"
        />
        <button onClick={handleSubscribe}>Suscribirse</button>
      </div>

      {/* Input para el mensaje */}
      <div>
        <label htmlFor="message">Mensaje:</label>
        <input 
          type="text" 
          id="message" 
          value={message} 
          onChange={(e) => setMessage(e.target.value)} 
          placeholder="Escribe un mensaje para publicar"
        />
        <button onClick={handlePublish}>Publicar</button>
      </div>

      {/* Lista de mensajes recibidos */}
      <div>
        <h2>Mensajes recibidos:</h2>
        <ul>
          {receivedMessages.map((msg, index) => (
            <li key={index}>{msg}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MqttClient;
