import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import config from './config.json';

const Footer: React.FC = () => {
  return (
    <Box sx={{ backgroundColor: '#121212', padding: 3, color: '#ffffff' }}>
      <Typography variant="body1" align="center" sx={{ fontFamily: 'Roboto, sans-serif' }}>
        {config.footer.text}
      </Typography>
      <Box sx={{ textAlign: 'center', marginTop: 2 }}>
        {config.footer.links.map((link, index) => (
          <Link key={index} href={link.url} color="inherit" sx={{ marginRight: 2 }}>
            {link.text}
          </Link>
        ))}
      </Box>
    </Box>
  );
};

export default Footer;
