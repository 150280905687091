import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import UserManagement from './UserManagement.tsx';
import Login from './login.tsx';
import WebSocketMqttClient from './WebSocketMqttClient';
import DeviceManagement from './DeviceManagement';
import MqttClient from './MqttClient';
import Dashboard from './Dashboard';
import DeviceMonitor from './DeviceMonitor.tsx';
import IoTDarkThemePage from './IoTDarkThemePage.tsx';
import Appioayudo from './Appioayudo.tsx';
import Main from './other/main';

function App() {
  return (
    <Router>
      <Routes>
      <Route path="/main" element={<Appioayudo />} />
      <Route path="/" element={<Main />} />
      <Route path="/Login" element={<Login />} />
      <Route path="/user-management" element={<UserManagement />} />
        <Route path="/websockets" element={<WebSocketMqttClient />} />
        <Route path="/DeviceManagement" element={<DeviceManagement />} />
        <Route path="/devicemonitor/:qrcode" element={<DeviceMonitor />} />
        <Route path="/MqttClient" element={<MqttClient />} />
        <Route path="/Dashboard/:qrcode" element={<Dashboard />} />
        <Route path="/IoTDarkThemePage"  />
        
      </Routes>
    </Router>
  );
}

export default App;
